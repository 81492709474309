import { NetTvType } from '@ncs-frontend-monorepo/availability';
import { ProductId, TemplateGroup } from '@ncs-frontend-monorepo/order';

export const TV_SHORT_DESCRIPTIONS = {
  [ProductId.TV_INTERNET]: 'Volle Sendervielfalt - viele in HD-Qualität',
  [ProductId.TV_CABLE]: 'Über 95 Sender',
  [ProductId.TV_CABLE_EXISTING_CUSTOMER]: 'Über 95 Sender',
  [ProductId.TV_CABLE_GWG_NEUSS]: 'Über 95 Sender',
  [ProductId.TV_CABLE_HD]: '25 Sender in HD-Qualität',
  [ProductId.TV_CABLE_PREMIUM_HD]:
    '42 Sender in gestochen scharfer HD-Qualität',
  [ProductId.TV_CABLE_FAMILY_HD]: '61 Sender in exzellenter HD-Qualität',
};

export const TV_LONG_DESCRIPTIONS = {
  [ProductId.TV_CABLE_PREMIUM_HD]: 'HD-Option + 17 weitere HD-Sender',
  [ProductId.TV_CABLE_FAMILY_HD]:
    'HD-Option inkl. Premium HD + 19 weitere Sender',
};

export const TV_CHANNEL_DESCRIPTIONS = {
  [ProductId.TV_CABLE_HD]: '25 Sender in HD-Qualität',
  [ProductId.TV_CABLE_PREMIUM_HD]: '42 Sender in HD-Qualität',
  [ProductId.TV_CABLE_FAMILY_HD]: '61 Sender in HD-Qualität',
};

export const TV_PLAN_DESCRIPTION = {
  [NetTvType.NET_TV_APP]:
    'Erlebe rund 80 Sender auf deinem Smartphone, Tablet und Computer.',
  [NetTvType.NET_TV_INTERNET]:
    'Schaue rund 100 Sender wann du möchtest. Egal ob auf dem Fernseher, Laptop oder Tablet.',
  [NetTvType.NET_TV_CABLE]:
    'Beste Unterhaltung mit rund 95 Sendern. Viele in brillantem HD.',
};

export const TV_OPTIONS = [
  ProductId.TV_CABLE_HD,
  ProductId.TV_CABLE_PREMIUM_HD,
  ProductId.TV_CABLE_FAMILY_HD,
];

export const CABLE_TV_PRODUCT_IDS = [
  ProductId.TV_CABLE,
  ProductId.TV_CABLE_EXISTING_CUSTOMER,
  ProductId.TV_CABLE_GWG_NEUSS,
  ...TV_OPTIONS,
];

export const CABLE_TV_COMBI_GROUPS = [
  TemplateGroup.KOMBI_TV_CABLE,
  TemplateGroup.KOMBI_PHONE_TV_CABLE,
];

export const TV_GROUPS = [TemplateGroup.TV_CABLE, TemplateGroup.TV_INTERNET];

type TVContent = {
  [key in ProductId]?: TVContentItem;
};

interface TVContentItem {
  headline: string;
  subHeadline?: string;
  listHeadline?: string;
  list?: string[];
}

const tvContent: TVContent = {
  [ProductId.TV_INTERNET]: {
    headline: 'Alles auf einen Blick:',
    subHeadline: 'Im Produkt enthalten',
    list: [
      'Rund 100 Sender - viele in HD-Qualität',
      'Flexibel Fernsehen mit Aufnahme-, Restart- und Replay-Funktionen',
      'Fernsehen auf bis zu 5 Endgeräten gleichzeitig - egal ob SmartTV, FireTV oder Tablet.',
    ],
  },
  [ProductId.TV_CABLE]: {
    headline: 'Alles auf einen Blick:',
    subHeadline: 'Im Tarif enthalten',
    listHeadline: 'Erlebe die volle Sendervielfalt mit NetTV über Kabel:',
    list: [
      'Über 95 Sender, davon mehr als 25 HD-Sender',
      'Ohne Mindestvertragslaufzeit',
      'Monatlich kündbar',
      'Keine Aktivierungsgebühr',
    ],
  },
  [ProductId.TV_CABLE_GWG_NEUSS]: {
    headline: 'Alles auf einen Blick:',
    subHeadline: 'Im Tarif enthalten',
    listHeadline: 'Erlebe die volle Sendervielfalt mit NetTV über Kabel:',
    list: [
      'Über 95 Sender, davon mehr als 25 HD-Sender',
      'Ohne Mindestvertragslaufzeit',
      'Monatlich kündbar',
      'Keine Aktivierungsgebühr',
    ],
  },
  [ProductId.TV_CABLE_HD]: {
    headline: 'HD-Option auf einen Blick:',
    subHeadline: 'Vorteile',
    listHeadline: 'Empfange deine Lieblingssender in brillantem HD.',
    list: ['25 HD-Privatsender', '2 Monate gratis', 'Monatlich kündbar'],
  },
};

export const TV_CONTENT: TVContent = {
  ...tvContent,
  ...{
    [ProductId.TV_CABLE_EXISTING_CUSTOMER]: tvContent[ProductId.TV_CABLE],
    [ProductId.TV_CABLE_GWG_NEUSS]: tvContent[ProductId.TV_CABLE],
  },
};

export const NETTV_DOWNLOAD_LINKS = {
  [NetTvType.NET_TV_APP]: {
    NetAachen:
      'https://www.netaachen.de/cms/api/fileadmin/user_upload/netaachen/pdf/programmbelegungen-senderlisten/senderliste-nettv-ueber-internet.pdf',
    NetCologne:
      'https://www.netcologne.de/cms/api/fileadmin/user_upload/privatkunden/pdf/programmbelegungen-senderlisten/senderliste-nettv-ueber-internet.pdf',
  },
  [NetTvType.NET_TV_INTERNET]: {
    NetAachen:
      'https://www.netaachen.de/cms/api/fileadmin/user_upload/netaachen/pdf/programmbelegungen-senderlisten/senderliste-nettv-ueber-internet.pdf',
    NetCologne:
      'https://www.netcologne.de/cms/api/fileadmin/user_upload/privatkunden/pdf/programmbelegungen-senderlisten/senderliste-nettv-ueber-internet.pdf',
  },
  [NetTvType.NET_TV_CABLE]: {
    NetAachen:
      'https://www.netaachen.de/cms/api/fileadmin/user_upload/privatkunden/pdf/programmbelegungen-senderlisten/digitale-kanalbelegung.pdf',
    NetCologne:
      'https://www.netcologne.de/cms/api/fileadmin/user_upload/privatkunden/pdf/programmbelegungen-senderlisten/digitale-kanalbelegung.pdf',
  },
};

export const CHANNEL_PATH =
  '/fileadmin/user_upload/privatkunden/logos/senderlogos';

enum ChannelNames {
  ZDF_NEO_HD = 'zdf-neo-hd',
  ZDF_HD = 'zdf-hd',
  WELT = 'welt',
  WDR_HD = 'wdr-hd',
  VOX = 'vox',
  TLC = 'tlc',
  TELE_5 = 'tele5',
  TAGESCHAU24_HD = 'tagesschau24-hd',
  SWR_HD = 'swr-hd',
  SUPER_RTL = 'super-rtl',
  SPORT1 = 'sport1',
  SONNENKLARTV = 'sonnenklartv',
  SONNENKLARTV_HD = 'sonnenklartv-hd',
  SIXX = 'sixx',
  SERVUSTV_HD = 'servustv-hd',
  SAT1_GOLD = 'sat1-gold',
  SAT1 = 'sat1',
  RTL = 'rtl',
  RTL_ZWEI = 'rtl-zwei',
  RBB_HD = 'rbb-hd',
  QVC2 = 'qvc2',
  QVC = 'qvc',
  QVC_HD = 'qvc-hd',
  QVC_STYLE = 'qvc-style',
  PROSIEBEN = 'prosieben',
  PHOENIX = 'phoenix',
  ONE_HD = 'one-hd',
  NTV = 'ntv',
  NRWWISION = 'nrwision',
  NRWWISION_HD = 'nrwision-hd',
  NITRO = 'nitro',
  NICK = 'nick',
  NDR_HD = 'ndr-hd',
  MUSIKFERNSEHEN = 'musikfernsehen',
  MDR_THUERINGEN = 'mdr_thueringen',
  MDR_SACHEN_ANHALT = 'mdr-sachsen-anhalt',
  MDR_HD = 'mdr-hd',
  KTV = 'ktv',
  KIKA_HD = 'kika-hd',
  KABEL1 = 'kabel1',
  KABEL1_DOKU = 'kabel1-doku',
  HSE = 'hse',
  HR_HD = 'hr-hd',
  ZDF_INFO_HD = 'zdf-info-hd',
  HOMEGARDEN = 'homegarden_tv',
  FRANCE24 = 'france24',
  EUROSPORT1 = 'eurosport1',
  DMAX = 'dmax',
  DAS_ERSTE_HD = 'daserste-hd',
  COMEDY_CENTRAL = 'comedy-central',
  CNBC = 'cnbc',
  CHANNEL21 = 'channel21',
  BRAND_DELUXE_NEU = 'brand_deluxe_neu',
  BR_HD = 'br-hd',
  BIBELTV_HD = 'bibeltv-hd',
  ASTRO_TV = 'astro_tv',
  ARTE_HD = 'arte-hd',
  ANIXE_HD_SERIE = 'anixe-hd-serie',
  ALPHA_HAD = 'alpha-hd',
  SIEBEN_MAXX = '7maxx',
  DREISAT_HD = '3sat-hd',
  TV_123_HD = '123tv-hd',
  RTL_HD = 'rtl-hd',
  SAT1_HD = 'sat1-hd',
  VOX_HD = 'vox-hd',
  PROSIEBEN_HD = 'prosieben-hd',
  RTL_ZWEI_HD = 'rtl-zwei-hd',
  KABEL_EINS_HD = 'kabel-eins-hd',
  DISNEY_CHANNEL_HD = 'disney-channel-hd',
  DISNEY_CHANNEL = 'disney-channel',
  COMEDY_CENTRAL_HD = 'comedy-central-hd',
  DELUXE_MUSIC_HD = 'deluxe-music-hd',
  DELUXE_MUSIC = 'deluxe-music',
  DELUXE_SCHLAGER = 'deluxe-schlager',
  DMAX_HD = 'dmax-hd',
  EUROSPORT1_HD = 'eurosport1-hd',
  MTV = 'mtv',
  MTV_HD = 'mtv-hd',
  NICK_HD = 'nick-hd',
  NITRO_HD = 'nitro-hd',
  NTV_HD = 'ntv-hd',
  PROSIEBEN_MAXX_HD = 'prosieben-maxx-hd',
  PROSIEBEN_MAXX = 'prosieben-maxx',
  SAT1_GOLD_HD = 'sat1-gold-hd',
  SIXX_HD = 'sixx-hd',
  SPORT1_HD = 'sport1-hd',
  SUPER_RTL_HD = 'super-rtl-hd',
  TELE_5_HD = 'tele5-hd',
  TLC_HD = 'tlc-hd',
  WELT_HD = 'welt-hd',
  STREET_13TH_HD = '13th-street-hd',
  GEO_HD = 'geo-hd',
  NATIONAL_GEOGRAPHIC_HD = 'national-geographic-hd',
  PROSIEBEN_FUN_HD = 'prosieben-fun-hd',
  RTL_CRIME_HD = 'rtl-crime-hd',
  SONY_AXN_HD = 'sony-axn-hd',
  SYFY_HD = 'syfy-hd',
  CRIME_INVESTIGATION_HD = 'crime-investigation-hd',
  KABEL_EINS_CLASSIC_HD = 'kabel-eins-classic-hd',
  SPORTDIGITAL_FUSSBALL_HD = 'sportdigital-fussball-hd',
  SONY_CHANNEL_HD = 'sony-channel-hd',
  SAT1_EMOTIONS_HD = 'sat1-emotions-hd',
  RTL_PASSION_HD = 'rtl-passion-hd',
  PENTHOUSE_PASSION_HD = 'penthouse-passion-hd',
  MTV_LIVE_HD = 'mtv-live-hd',
  MEZZO_LIVE_HD = 'mezzo-live-hd',
  KINOWELT_TV = 'kinowelt-tv',
  NICK_JR = 'nick-jr',
  SPORT1_PLUS_HD = 'sport1-plus-hd',
  ROMANCE_TV = 'romance-tv',
  NAT_GEO_WILD_HD = 'nat-geo-wild-hd',
  HISTORY_HD = 'history-hd',
  AUTO_MOTOR_SPORT = 'auto-motor-sport-channel',
  DUCK_TV = 'duck-tv',
  FIX_FOXI_TV = 'fix-foxi-tv',
  RTL_LIVING_HD = 'rtl-living-hd',
  PLAYBOY_TV = 'playboy-tv',
  OUT_TV = 'out-tv',
  MTV_80S = 'mtv-80s',
  MARCO_POLO_TV = 'marco-polo-tv',
  HEIMATKANAL = 'heimatkanal',
  GUTE_LAUNE_TV = 'gute-laune-tv',
  UNIVERSAL_TV_HD = 'universal-tv-hd',
  CURIOSITY_CHANNEL_HD = 'curiosity-channel-hd',
  CLASSICA_HD = 'classica-hd',
  FAST_AND_FUN_BOX_HD = 'fast-and-fun-box-hd',
  FASHION_TV = 'fashion-tv',
  JUWELO_HD = 'juwelo-hd',
  HANDYSTAR = 'handystar',
  SHOP_LC = 'shop-lc',
  ANIXE = 'anixe',
  VOLKSMUSIKTV = 'volksmusik-tv',
  EURONEWS = 'euronews',
  CATHOLICTV = 'catholic-tv',
  ORF2 = 'orf2',
  EWTN_TV = 'ewtn-tv',
  N24_DOKU = 'n24-doku',
  BBC_WORLD = 'bbc-world',
  BLOOMBERG_TV = 'bloomberg-tv',
  CNN_INT = 'cnn-int',
  GOD_TV = 'god-tv',
  NHK_WORLD = 'nhk-world',
  SONLIFE_BROADCASTING = 'sonlife-broadcasting',
  FRANCE2 = 'france2',
  FRANCE3 = 'france3',
  FRANCE5 = 'france5',
  TV5_MONDE_EUROPE = 'tv5-monde',
  NPO1 = 'npo1',
  NPO2 = 'npo2',
  TGRT_EU = 'tgrt-eu',
  INTERNATIONAL_1PLUS1 = 'international-1plus1',
  TUNISIA1 = 'tunisia1',
  KBS_WORLD_HD = 'kbs-world-hd',
  TVR_INTERNATIONAL = 'tvr-international',
  BILD_HD = 'bild-hd',
  AXN_BLACK = 'axn-black',
  AXN_WHITE = 'axn-white',
}

type TVChannels = {
  [ProductId.TV_INTERNET]: ChannelNames[];
  [ProductId.TV_CABLE]: ChannelNames[];
  [ProductId.TV_CABLE_EXISTING_CUSTOMER]?: ChannelNames[];
  [ProductId.TV_CABLE_GWG_NEUSS]?: ChannelNames[];
  [ProductId.TV_CABLE_HD]: ChannelNames[];
  [ProductId.TV_CABLE_PREMIUM_HD]: ChannelNames[];
  [ProductId.TV_CABLE_FAMILY_HD]: ChannelNames[];
};

const channelList: TVChannels = {
  [ProductId.TV_INTERNET]: [
    ChannelNames.DAS_ERSTE_HD,
    ChannelNames.ZDF_HD,
    ChannelNames.RTL,
    ChannelNames.SAT1,
    ChannelNames.PROSIEBEN,
    ChannelNames.VOX,
    ChannelNames.KABEL1,
    ChannelNames.SUPER_RTL,
    ChannelNames.RTL_ZWEI,
    ChannelNames.ZDF_NEO_HD,
    ChannelNames.ZDF_INFO_HD,
    ChannelNames.WELT,
    ChannelNames.WDR_HD,
    ChannelNames.TLC,
    ChannelNames.TELE_5,
    ChannelNames.TAGESCHAU24_HD,
    ChannelNames.SWR_HD,
    ChannelNames.SPORT1,
    ChannelNames.SONNENKLARTV,
    ChannelNames.SIXX,
    ChannelNames.SERVUSTV_HD,
    ChannelNames.SAT1_GOLD,
    ChannelNames.RBB_HD,
    ChannelNames.QVC2,
    ChannelNames.QVC,
    ChannelNames.PHOENIX,
    ChannelNames.ONE_HD,
    ChannelNames.NTV,
    ChannelNames.NRWWISION,
    ChannelNames.NITRO,
    ChannelNames.NICK,
    ChannelNames.NDR_HD,
    ChannelNames.MUSIKFERNSEHEN,
    ChannelNames.MDR_THUERINGEN,
    ChannelNames.MDR_SACHEN_ANHALT,
    ChannelNames.BR_HD,
    ChannelNames.MDR_HD,
    ChannelNames.KTV,
    ChannelNames.KIKA_HD,
    ChannelNames.KABEL1_DOKU,
    ChannelNames.HSE,
    ChannelNames.HR_HD,
    ChannelNames.MTV,
    ChannelNames.HOMEGARDEN,
    ChannelNames.FRANCE24,
    ChannelNames.EUROSPORT1,
    ChannelNames.DMAX,
    ChannelNames.COMEDY_CENTRAL,
    ChannelNames.CNBC,
    ChannelNames.CHANNEL21,
    ChannelNames.BRAND_DELUXE_NEU,
    ChannelNames.BIBELTV_HD,
    ChannelNames.ASTRO_TV,
    ChannelNames.ANIXE_HD_SERIE,
    ChannelNames.ALPHA_HAD,
    ChannelNames.SIEBEN_MAXX,
    ChannelNames.DREISAT_HD,
    ChannelNames.FASHION_TV,
  ],
  [ProductId.TV_CABLE]: [
    ChannelNames.DAS_ERSTE_HD,
    ChannelNames.ZDF_HD,
    ChannelNames.RTL,
    ChannelNames.SAT1,
    ChannelNames.PROSIEBEN,
    ChannelNames.VOX,
    ChannelNames.WDR_HD,
    ChannelNames.RTL_ZWEI,
    ChannelNames.KABEL1,
    ChannelNames.DREISAT_HD,
    ChannelNames.ARTE_HD,
    ChannelNames.DMAX,
    ChannelNames.SERVUSTV_HD,
    ChannelNames.HSE,
    ChannelNames.QVC_HD,
    ChannelNames.CHANNEL21,
    ChannelNames.SONNENKLARTV_HD,
    ChannelNames.TV_123_HD,
    ChannelNames.JUWELO_HD,
    ChannelNames.QVC2,
    ChannelNames.QVC_STYLE,
    ChannelNames.HANDYSTAR,
    ChannelNames.SHOP_LC,
    ChannelNames.ZDF_NEO_HD,
    ChannelNames.NITRO,
    ChannelNames.TELE_5,
    ChannelNames.ONE_HD,
    ChannelNames.DISNEY_CHANNEL,
    ChannelNames.SIXX,
    ChannelNames.SAT1_GOLD,
    ChannelNames.PROSIEBEN_MAXX,
    ChannelNames.COMEDY_CENTRAL,
    ChannelNames.HOMEGARDEN,
    ChannelNames.TLC,
    ChannelNames.ANIXE,
    ChannelNames.MTV,
    ChannelNames.VOLKSMUSIKTV,
    ChannelNames.SPORT1,
    ChannelNames.EUROSPORT1,
    ChannelNames.PHOENIX, // siehe kommentar -> https://netwelt.netcologne.intern/display/OP/NetTV+Einzelinkasso+-+Senderlogos
    ChannelNames.NTV,
    ChannelNames.WELT,
    ChannelNames.ZDF_INFO_HD,
    ChannelNames.TAGESCHAU24_HD,
    ChannelNames.EURONEWS,
    ChannelNames.SUPER_RTL,
    ChannelNames.KIKA_HD,
    ChannelNames.NICK,
    ChannelNames.DELUXE_MUSIC,
    ChannelNames.MUSIKFERNSEHEN,
    ChannelNames.DELUXE_SCHLAGER,
    ChannelNames.ALPHA_HAD,
    ChannelNames.CATHOLICTV,
    ChannelNames.BIBELTV_HD,
    ChannelNames.ASTRO_TV,
    ChannelNames.ORF2,
    ChannelNames.EWTN_TV,
    ChannelNames.N24_DOKU,
    ChannelNames.KABEL1_DOKU,
    ChannelNames.NDR_HD,
    ChannelNames.MDR_HD,
    ChannelNames.SWR_HD,
    ChannelNames.BR_HD,
    ChannelNames.HR_HD,
    ChannelNames.RBB_HD,
    ChannelNames.NRWWISION_HD, // siehe kommentar -> https://netwelt.netcologne.intern/display/OP/NetTV+Einzelinkasso+-+Senderlogos
    ChannelNames.BBC_WORLD,
    ChannelNames.BLOOMBERG_TV,
    ChannelNames.CNBC,
    ChannelNames.CNN_INT,
    ChannelNames.GOD_TV,
    ChannelNames.NHK_WORLD,
    ChannelNames.SONLIFE_BROADCASTING,
    ChannelNames.FRANCE2,
    ChannelNames.FRANCE3,
    ChannelNames.FRANCE5,
    ChannelNames.TV5_MONDE_EUROPE,
    ChannelNames.NPO1,
    ChannelNames.NPO2,
    ChannelNames.TGRT_EU,
    ChannelNames.INTERNATIONAL_1PLUS1,
    ChannelNames.TUNISIA1,
    ChannelNames.KBS_WORLD_HD, // siehe kommentar -> https://netwelt.netcologne.intern/display/OP/NetTV+Einzelinkasso+-+Senderlogos
    ChannelNames.TVR_INTERNATIONAL,
    ChannelNames.BILD_HD, // siehe kommentar -> https://netwelt.netcologne.intern/display/OP/NetTV+Einzelinkasso+-+Senderlogos
  ],
  [ProductId.TV_CABLE_HD]: [
    ChannelNames.RTL_HD,
    ChannelNames.PROSIEBEN_HD,
    ChannelNames.SAT1_HD,
    ChannelNames.VOX_HD,
    ChannelNames.KABEL_EINS_HD,
    ChannelNames.SUPER_RTL_HD,
    ChannelNames.RTL_ZWEI_HD,
    ChannelNames.DMAX_HD,
    ChannelNames.NITRO_HD,
    ChannelNames.TELE_5_HD,
    ChannelNames.DISNEY_CHANNEL_HD,
    ChannelNames.SIXX_HD,
    ChannelNames.SAT1_GOLD_HD,
    ChannelNames.PROSIEBEN_MAXX_HD,
    ChannelNames.COMEDY_CENTRAL_HD,
    ChannelNames.TLC_HD,
    ChannelNames.ANIXE_HD_SERIE,
    ChannelNames.MTV_HD,
    ChannelNames.SPORT1_HD,
    ChannelNames.EUROSPORT1_HD,
    ChannelNames.NTV_HD,
    ChannelNames.WELT_HD,
    ChannelNames.NICK_HD,
    ChannelNames.DELUXE_MUSIC_HD,
    ChannelNames.KABEL1_DOKU,
  ],
  [ProductId.TV_CABLE_PREMIUM_HD]: [
    ChannelNames.STREET_13TH_HD,
    ChannelNames.GEO_HD,
    ChannelNames.NATIONAL_GEOGRAPHIC_HD,
    ChannelNames.PROSIEBEN_FUN_HD,
    ChannelNames.RTL_CRIME_HD,
    ChannelNames.AXN_BLACK,
    ChannelNames.SYFY_HD,
    ChannelNames.MTV_LIVE_HD,
    ChannelNames.RTL_PASSION_HD,
    ChannelNames.UNIVERSAL_TV_HD,
    ChannelNames.AXN_WHITE,
    ChannelNames.CRIME_INVESTIGATION_HD,
    ChannelNames.SPORTDIGITAL_FUSSBALL_HD,
    ChannelNames.MEZZO_LIVE_HD,
    ChannelNames.PENTHOUSE_PASSION_HD,
    ChannelNames.KABEL_EINS_CLASSIC_HD,
    ChannelNames.SAT1_EMOTIONS_HD,
  ],
  [ProductId.TV_CABLE_FAMILY_HD]: [
    ChannelNames.HISTORY_HD,
    ChannelNames.KINOWELT_TV,
    ChannelNames.NICK_JR,
    ChannelNames.SPORT1_PLUS_HD,
    ChannelNames.ROMANCE_TV,
    ChannelNames.NAT_GEO_WILD_HD,
    ChannelNames.CURIOSITY_CHANNEL_HD,
    ChannelNames.RTL_LIVING_HD,
    ChannelNames.CLASSICA_HD,
    ChannelNames.HEIMATKANAL,
    ChannelNames.MARCO_POLO_TV,
    ChannelNames.DUCK_TV,
    ChannelNames.FIX_FOXI_TV,
    ChannelNames.GUTE_LAUNE_TV,
    ChannelNames.FAST_AND_FUN_BOX_HD,
    ChannelNames.MTV_80S,
    ChannelNames.OUT_TV,
    ChannelNames.AUTO_MOTOR_SPORT,
    ChannelNames.PLAYBOY_TV,
  ],
};
export const TV_CHANNEL_LIST = {
  ...channelList,
  ...{
    [ProductId.TV_CABLE_EXISTING_CUSTOMER]: channelList[ProductId.TV_CABLE],
  },
};

export const TV_CHANNEL_LIST_COMPLETE: TVChannels = {
  [ProductId.TV_INTERNET]: TV_CHANNEL_LIST[ProductId.TV_INTERNET],
  [ProductId.TV_CABLE]: TV_CHANNEL_LIST[ProductId.TV_CABLE],
  [ProductId.TV_CABLE_EXISTING_CUSTOMER]: TV_CHANNEL_LIST[ProductId.TV_CABLE],
  [ProductId.TV_CABLE_HD]: TV_CHANNEL_LIST[ProductId.TV_CABLE_HD],
  [ProductId.TV_CABLE_PREMIUM_HD]: [
    ...TV_CHANNEL_LIST[ProductId.TV_CABLE_HD],
    ...TV_CHANNEL_LIST[ProductId.TV_CABLE_PREMIUM_HD],
  ],
  [ProductId.TV_CABLE_FAMILY_HD]: [
    ...TV_CHANNEL_LIST[ProductId.TV_CABLE_HD],
    ...TV_CHANNEL_LIST[ProductId.TV_CABLE_PREMIUM_HD],
    ...TV_CHANNEL_LIST[ProductId.TV_CABLE_FAMILY_HD],
  ],
};
