import { Fallback } from '@ncs-frontend-monorepo/availability';
import { OrderAction, OrderActionType, OrderState } from './';

export const orderReducer = (
  state: OrderState,
  action: OrderAction,
): OrderState => {
  switch (action.type) {
    case OrderActionType.SET_ADDRESS:
      return {
        ...state,
        address: action.payload.address,
        maxAvailableDownload: null,
        plannedAvailability: null,
        availableTv: null,
        basket: null,
        basketId: null,
        shoppingCartData: null,
        availableProductOptions: null,
        ftthPresalesInformation: null,
        inkassoTypeValidFrom: null,
        products: null,
      };
    case OrderActionType.SET_AVAILABILITY:
      return {
        ...state,
        maxAvailableDownload: action.payload.maxAvailableDownload,
        ...(action.payload.plannedAvailability
          ? { plannedAvailability: action.payload.plannedAvailability }
          : { plannedAvailability: null }),
        ...(action.payload.availableTv
          ? { availableTv: action.payload.availableTv }
          : { availableTv: null }),
        ...(action.payload.promotions
          ? { promotions: action.payload.promotions }
          : { promotions: null }),
        ...(action.payload.address && { address: action.payload.address }),
        ...(action.payload.fallback
          ? { fallback: action.payload.fallback }
          : { fallback: Fallback.NONE }),
      };
    case OrderActionType.ADD_PRODUCT_TO_BASKET:
      return {
        ...state,
        basket: { ...action.payload.product },
      };
    case OrderActionType.SAVE_ORDER_FORM_DATA:
      return {
        ...state,
        orderFormData: { ...action.payload },
      };
    case OrderActionType.SET_IS_YOUNG:
      return {
        ...state,
        isYoung: action.payload,
      };
    case OrderActionType.CLEAR_BASKET:
      return {
        ...state,
        basket: null,
        basketId: null,
        shoppingCartData: null,
      };
    case OrderActionType.CLEAR_ORDER:
      return {
        ...state,
        address: null,
        maxAvailableDownload: null,
        plannedAvailability: null,
        availableTv: null,
        basket: null,
        basketId: null,
        shoppingCartData: null,
        orderFormData: action.payload?.keepFormData
          ? state.orderFormData
          : null,
        availableProductOptions: null,
        ftthPresalesInformation: null,
        promotions: null,
        products: null,
      };
    case OrderActionType.CLEAR_ORDER_FORM_DATA:
      return {
        ...state,
        orderFormData: null,
      };
    case OrderActionType.SET_FTTH_PRESALES_INFORMATION: {
      return {
        ...state,
        ftthPresalesInformation: { ...action.payload },
      };
    }
    case OrderActionType.SET_INKASSO_TYPE_VALID_FROM:
      return {
        ...state,
        inkassoTypeValidFrom: action.payload,
      };
    case OrderActionType.SET_AVAILABLE_PRODUCT_OPTIONS:
      return {
        ...state,
        availableProductOptions: {
          devices: [...action.payload.devices],
          ontDevices: [...action.payload.ontDevices],
          installationServices: [...action.payload.installationServices],
        },
      };
    case OrderActionType.SET_BASKET_ID:
      return {
        ...state,
        basketId: action.payload,
      };
    case OrderActionType.SET_SHOPPING_CART_DATA:
      return {
        ...state,
        shoppingCartData: action.payload,
      };
    case OrderActionType.ADD_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          ...action.payload,
        },
      };
    case OrderActionType.SAVE_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
