import { createContext, ReactNode, useContext } from 'react';
import { StepProps } from 'dss-ui-library';
import { ProductId, TemplateGroup } from '../constants';
import { createStore, useStore } from 'zustand';
import { OrderStepIds } from '../utils';

export type OrderStep = Pick<StepProps, 'title' | 'shortTitle'> & {
  id: OrderStepIds;
  path: string;
  isFormStep?: boolean;
};
export interface OrderPageValues {
  BU: 'PK' | 'GK';
  steps: OrderStep[];
  stepIndex: number;
  group?: TemplateGroup;
  id?: ProductId;
}

type OrderStoreProvider = OrderPageValues & { children: ReactNode };

const createOrderPageStore = (storeValues: OrderPageValues) =>
  createStore<OrderPageValues>()(() => storeValues);

type OrderStore = ReturnType<typeof createOrderPageStore>;

const OrderPageContext = createContext<OrderStore | null>(null);

export const useOrderPageContext: () => OrderPageValues = () => {
  const storeApi = useContext(OrderPageContext);
  if (storeApi === null) {
    throw new Error(
      'useOrderStoreContext must be used within a <OrderStoreContext> tag',
    );
  }

  return useStore(storeApi, (state) => state);
};

export const OrderPageProvider = (props: OrderStoreProvider) => {
  const { children, ...orderValues } = props;
  const store = createOrderPageStore(orderValues);

  return (
    <OrderPageContext.Provider value={store}>
      {children}
    </OrderPageContext.Provider>
  );
};
