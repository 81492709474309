import { Portfolio } from '../components/AvailabilityCheck/Context';
import { OrderPromotion, OrderState } from '../components/OrderContext';
import { PLANNED_FTTH_STATUS } from '../constants';
import { isFiberPlan } from './plan';
import { ProductId, TemplateGroup } from '@ncs-frontend-monorepo/order';
import { CABLE_TV_COMBI_GROUPS } from '../constants/tv';
import {
  ExpansionStatus,
  Fallback,
  LandlinePromotion,
  NetTvType,
  ObjectInformation,
  Technology,
} from '@ncs-frontend-monorepo/availability';

export const classicTechnologies = [
  Technology.ADSL2_IP,
  Technology.DSL_ISDN,
  Technology.DSL_ANALOG,
  Technology.CABLE,
  Technology.VDSL_BSA_L2,
  Technology.VDSL_BSA_L3,
  Technology.FTTC,
  Technology.ADSL,
];

export const fiberTechnologies = [
  Technology.FTTH,
  Technology.GFAST,
  Technology.VDSL2_FTTB,
  Technology.FTTH_BSA_L2,
  Technology.FTTH_BSA_L2_DG,
];

export const fallbackProductIds = [
  ProductId.NETSPEED_175,
  ProductId.NETSPEED_PHONE_175,
  ProductId.NETSPEED_TV_175,
  ProductId.NETSPEED_PHONE_TV_175,
  ProductId.NETSPEED_25,
  ProductId.NETSPEED_PHONE_25,
  ProductId.NETSPEED_TV_25,
  ProductId.NETSPEED_PHONE_TV_25,
  ProductId.NETSPEED_YOUNG_175,
  ProductId.NETSPEED_YOUNG_PHONE_175,
  ProductId.NETSPEED_YOUNG_TV_175,
  ProductId.NETSPEED_YOUNG_PHONE_TV_175,
  ProductId.NETSPEED_YOUNG_25,
  ProductId.NETSPEED_YOUNG_PHONE_25,
  ProductId.NETSPEED_YOUNG_TV_25,
  ProductId.NETSPEED_YOUNG_PHONE_TV_25,
];

const hasGFAST = (availablePromotions: LandlinePromotion[]) =>
  !!availablePromotions.find(
    ({ technology }) => technology === Technology.GFAST,
  );

export const isClassicPlan = (technology: Technology): boolean =>
  classicTechnologies.includes(technology);

interface MaxDownloads {
  maxAvailableDownload: number | null;
  plannedAvailability: {
    maxAvailableDownload: number;
    plannedAvailabilityDate: string;
    plannedAvailabilityDateDescription: string;
  } | null;
}

interface MaxDownloadsProps {
  availablePromotions: LandlinePromotion[] | null;
  presalePromotion: LandlinePromotion | null;
}

export const getMaxDownloads = ({
  availablePromotions,
  presalePromotion,
}: MaxDownloadsProps): MaxDownloads => {
  const maxAvailableDownload =
    availablePromotions?.length > 0
      ? availablePromotions?.reduce((p, c) =>
          p.maxDownload > c.maxDownload ? p : c,
        )?.maxDownload
      : 0;

  const plannedAvailability = presalePromotion
    ? {
        plannedAvailabilityDate: presalePromotion.plannedAvailabilityDate,
        plannedAvailabilityDateDescription:
          presalePromotion.plannedAvailabilityDateDescription,
        maxAvailableDownload: presalePromotion.maxDownload,
      }
    : null;
  return {
    maxAvailableDownload,
    plannedAvailability,
  };
};

export const getPromotionWithMaxDownload = (
  promotions: LandlinePromotion[],
): LandlinePromotion | null =>
  promotions?.length > 0
    ? promotions?.reduce((p, c) => (p.maxDownload > c.maxDownload ? p : c))
    : null;

export const getTechnologyDownloads = (
  promotions: LandlinePromotion[],
  presalePromotion: LandlinePromotion,
): OrderPromotion[] => [
  ...(presalePromotion
    ? [
        {
          technology: presalePromotion.technology,
          maxDownload: presalePromotion.maxDownload,
          portfolio: getPortfolio([presalePromotion]),
        },
      ]
    : []),
  ...(promotions?.map((promotion) => ({
    technology: promotion.technology,
    maxDownload: promotion.maxDownload,
    portfolio: getPortfolio([promotion]),
  })) || []),
];

export const hasTechnologies = ({
  technologies,
  availablePromotions,
  presalePromotion,
}: {
  technologies: Technology[];
  availablePromotions?: LandlinePromotion[];
  presalePromotion?: LandlinePromotion;
}): boolean =>
  availablePromotions?.some((promotion) =>
    technologies.includes(promotion?.technology),
  ) ||
  technologies.includes(presalePromotion?.technology) ||
  false;

export const hasCableTV = (tvPromotions: Array<keyof typeof NetTvType>) => {
  return [
    NetTvType.NET_TV_CABLE,
    NetTvType.NET_TV_FTTH,
    NetTvType.NET_TV_CABLE_GWG_NEUSS,
  ].some((type) => tvPromotions?.includes(type));
};

export const hasCableOptions = (tvPromotions: Array<keyof typeof NetTvType>) =>
  tvPromotions?.includes(NetTvType.NET_TV_CABLE_OPTIONS);

export const isDisabledCableTVGroup = ({
  templateGroup,
  availableTv,
}: {
  templateGroup: TemplateGroup;
  availableTv: OrderState['availableTv'];
}) => {
  if (
    templateGroup === TemplateGroup.TV_CABLE ||
    CABLE_TV_COMBI_GROUPS.includes(templateGroup)
  ) {
    return !hasCableTV(availableTv);
  }
  return false;
};

const getPortfolio = (availablePromotions: LandlinePromotion[]): Portfolio => {
  if (availablePromotions.length > 0) {
    return isFiberPlan(availablePromotions[0].technology) ? 'FIBER' : 'CLASSIC';
  }
  return 'FIBER';
};

const hasFtthStatus = ({
  expansionStatus,
  isFiberTechnology,
}: {
  expansionStatus: ExpansionStatus;
  isFiberTechnology: boolean;
}) =>
  isFiberTechnology ? false : PLANNED_FTTH_STATUS.includes(expansionStatus);

export const createAvailabilityList = ({
  objectInformation,
  availablePromotions,
  presalePromotion,
  hasFallback,
}: {
  objectInformation: ObjectInformation;
  availablePromotions: LandlinePromotion[];
  presalePromotion: LandlinePromotion;
  hasFallback: Fallback;
}) => {
  const hasFtthDate = objectInformation?.availabilityDate;
  const hasAvailability =
    hasFallback !== Fallback.ADSL && availablePromotions.length > 0;
  const isFiberTechnology = availablePromotions.some(({ technology }) =>
    isFiberPlan(technology),
  );

  // not available
  if (
    !availablePromotions?.length &&
    !presalePromotion &&
    !PLANNED_FTTH_STATUS.includes(objectInformation?.status)
  )
    return ['NOT_AVAILABLE'];

  // adsl
  if (
    hasFallback === Fallback.ADSL &&
    !(
      PLANNED_FTTH_STATUS.includes(objectInformation?.status) ||
      presalePromotion
    )
  )
    return ['ADSL'];

  // presale
  if (presalePromotion)
    return [
      getPortfolio([presalePromotion]),
      ...(hasAvailability ? ['AVAILABLE'] : []),
      ...(hasGFAST([presalePromotion]) ? ['GFAST'] : []),
      'PRESALE',
    ];

  return [
    ...(hasAvailability ? [getPortfolio(availablePromotions)] : []),
    ...(hasAvailability ? ['AVAILABLE'] : []),
    ...(hasFtthStatus({
      expansionStatus: objectInformation?.status,
      isFiberTechnology,
    })
      ? [
          hasFtthDate
            ? `${objectInformation.status}_DATE`
            : objectInformation.status,
        ]
      : []),
  ];
};
