import { getMaintenanceModeValue, MaintenanceMode } from '../maintenance';
import getConfig from 'next/config';
import { LOG_LEVELS, LogLevel } from '../logging';
import * as process from 'process';
import { BusinessUnit, getBusinessUnitFromString } from '../constants';

const { serverRuntimeConfig = {} } = getConfig() || {};
export interface Env {
  // URLs to the CMS Content API
  API_URL: string;
  API_V2_URL: string;
  // FILE URL to the CMS Content API
  API_FILE_URL: string;
  // URL to order API
  ORDER_SERVICE_URL: string;
  MAINTENANCE_MODE: MaintenanceMode;
  // Name of site
  SITE: 'NetCologne' | 'NetAachen';
  BUSINESS_UNIT: BusinessUnit;
  LOG_LEVEL: LogLevel;
  PUBLIC_FE_URL_NC: string;
  PUBLIC_FE_URL_NA: string;
  PK: EnvPK;
  GK: EnvGK;
}

export interface EnvPK {
  NEXT_PUBLIC_AUTH_DOMAIN: string;
  DSS_API_URL: string;
  FILE_SERVICE_URL: string; // todo: delete => deprecated
  EXPANSION_CHECK_URL: string; // todo: delete => deprecated
  SMARTCARD_ACTIVATION_URL: string;
  TRACKING_MODE: 'test' | 'production';
}

interface EnvGK {
  // alternative API_URL for usage in the client/browser
  API_URL_CLIENT: string;
  // hubspot forms
  HUBSPOT_SCRIPT_ID: string;
  // Sitemapstuff
  SITEMAP_URL_PREFIX: RegExp;
  SITEMAP_URL: string;
}

let env: Env | null = null;
export const isServerEnvironment = typeof window === 'undefined';
const isTestEnvironment = process.env.NODE_ENV === 'test';

const fillEnv = () => {
  if (isServerEnvironment || isTestEnvironment) {
    let site: Env['SITE'];
    let sitemapPrefix: EnvGK['SITEMAP_URL_PREFIX'];
    let sitemapUrl: EnvGK['SITEMAP_URL'];

    if (process.env.SITE) {
      if (process.env.SITE === 'NetCologne') {
        site = 'NetCologne';
        sitemapPrefix = RegExp(
          process.env.SITEMAP_URL_PREFIX_COLOGNE ||
            /http:\/\/localhost:8001\/cologne/,
          'g',
        );
        sitemapUrl =
          process.env.SITEMAP_URL_COLOGNE ||
          'http://localhost:8001/cologne/sitemap-gk-nc.xml?sitemap=pages&cHash=814b0d1f9d97e61a6544b7fa465409d0';
      } else if (process.env.SITE === 'NetAachen') {
        site = 'NetAachen';
        sitemapPrefix = RegExp(
          process.env.SITEMAP_URL_PREFIX_AACHEN ||
            /http:\/\/localhost:8002\/aachen/,
          'g',
        );
        sitemapUrl =
          process.env.SITEMAP_URL_AACHEN ||
          'http://localhost:8002/aachen/sitemap-gk-na.xml?sitemap=pages&cHash=c722d1f16f76c7f163c18bcc706b78cd';
      } else {
        throw new Error('Unknown SITE ' + process.env.SITE);
      }
    } else {
      site = 'NetCologne';
      sitemapPrefix = RegExp(
        process.env.SITEMAP_URL_PREFIX_COLOGNE ||
          /http:\/\/localhost:8001\/cologne/,
        'g',
      );
      sitemapUrl =
        'http://localhost:8001/cologne/sitemap-gk-nc.xml?sitemap=pages&cHash=814b0d1f9d97e61a6544b7fa465409d0';
    }

    env = {
      API_URL:
        serverRuntimeConfig?.apiUrl ||
        process.env.API_URL ||
        'https://wwwstaging.netcologne.de/cms/api',
      API_V2_URL:
        serverRuntimeConfig?.apiV2Url ||
        process.env.API_V2_URL ||
        'http://localhost:8000/_api',
      API_FILE_URL:
        serverRuntimeConfig?.apiFileUrl ||
        process.env.API_FILE_URL ||
        'https://wwwstaging.netcologne.de/cms/api',
      ORDER_SERVICE_URL:
        serverRuntimeConfig?.serviceUrl ||
        process.env.ORDER_SERVICE_URL ||
        'https://wwwstaging.netcologne.de/api',
      SITE: site,
      MAINTENANCE_MODE: getMaintenanceModeValue(
        process.env.MAINTENANCE_MODE || 'diabled',
      ),
      PUBLIC_FE_URL_NC:
        process.env.PUBLIC_FE_URL_NC || 'https://www.netcologne.de',
      PUBLIC_FE_URL_NA:
        process.env.PUBLIC_FE_URL_NA || 'https://www.netaachen.de',
      BUSINESS_UNIT: getBusinessUnitFromString(process.env.BUSINESS_UNIT),
      LOG_LEVEL:
        serverRuntimeConfig?.logLevel || process.env.LOG_LEVEL || 'ERROR',
      PK: {
        NEXT_PUBLIC_AUTH_DOMAIN:
          serverRuntimeConfig?.authDomain ||
          process.env.NEXT_PUBLIC_AUTH_DOMAIN,
        DSS_API_URL: serverRuntimeConfig?.dssApiUrl || process.env.DSS_API_URL,
        FILE_SERVICE_URL:
          process.env.FILE_SERVICE_URL ||
          'https://wwwstaging.netcologne.de/api',
        EXPANSION_CHECK_URL:
          process.env.EXPANSION_CHECK_URL ||
          'https://wwwstaging.netcologne.de/api',
        SMARTCARD_ACTIVATION_URL:
          process.env.SMARTCARD_ACTIVATION_URL ||
          'https://www.m7deutschland.de/wp-admin/admin-ajax.php',
        TRACKING_MODE: serverRuntimeConfig?.trackingMode || 'production',
      },
      GK: {
        API_URL_CLIENT: process.env.API_URL_CLIENT || '',
        SITEMAP_URL_PREFIX: sitemapPrefix,
        SITEMAP_URL: sitemapUrl,
        HUBSPOT_SCRIPT_ID: process.env.HUBSPOT_SCRIPT_ID || '26202506',
      },
    };

    if (!isTestEnvironment) {
      // eslint-disable-next-line no-console
      console.info('ENV:', env);
    }
  } else {
    throw new Error('Must be called on server');
  }
};

export const getEnv = (): Env => {
  if (!env && (isServerEnvironment || isTestEnvironment)) {
    fillEnv();
  }

  if (env) {
    return env;
  }

  console.error(new Error('Tried to access env before it was set'));

  return {
    API_URL: '',
    API_V2_URL: '',
    API_FILE_URL: '',
    ORDER_SERVICE_URL: '',
    SITE: 'NetCologne',
    MAINTENANCE_MODE: 'disabled',
    BUSINESS_UNIT: 'PK',
    LOG_LEVEL: LOG_LEVELS.ERROR,
    PUBLIC_FE_URL_NC: '',
    PUBLIC_FE_URL_NA: '',
    PK: {
      NEXT_PUBLIC_AUTH_DOMAIN: '',
      DSS_API_URL: '',
      FILE_SERVICE_URL: '',
      EXPANSION_CHECK_URL: '',
      SMARTCARD_ACTIVATION_URL: '',
      TRACKING_MODE: 'production',
    },
    GK: {
      API_URL_CLIENT: '',
      SITEMAP_URL_PREFIX: RegExp(/http:\/\/localhost:8001\/cologne/, 'g'),
      SITEMAP_URL: '',
      HUBSPOT_SCRIPT_ID: '',
    },
  };
};

export function setEnv(value: Env) {
  env = value;
}

export function isNC(): boolean {
  return getEnv().SITE === 'NetCologne';
}
