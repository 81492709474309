import { useEffect } from 'react';
import { create } from 'zustand';
import { BREAKPOINTS, BreakpointKey } from 'dss-ui-library';

interface BreakpointState {
  breakpoint: BreakpointKey;
  update: (newBreakpoint: BreakpointKey) => void;
}

export const useBreakpointStore = create<BreakpointState>()((set) => ({
  breakpoint: 'xs',
  update: (newBreakpoint) => set(() => ({ breakpoint: newBreakpoint })),
}));

/**
 * Updates the global breakpoint state on init and window resize.
 * Should only be used at the root level of the project.
 */
export const useInitBreakpointStore = () => {
  const setBreakpoint = useBreakpointStore((state) => state.update);

  useEffect(() => {
    const update = () => {
      const width = window.innerWidth;
      if (width >= BREAKPOINTS.xl) return setBreakpoint('xl');
      if (width >= BREAKPOINTS.l) return setBreakpoint('l');
      if (width >= BREAKPOINTS.m) return setBreakpoint('m');
      if (width >= BREAKPOINTS.s) return setBreakpoint('s');
      return setBreakpoint('xs');
    };

    update();
    window.addEventListener('resize', update);

    return () => {
      window.removeEventListener('resize', update);
    };
  }, []);
};
