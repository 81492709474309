import { InstallationService } from '../utils/ftthOrderAPI/interface';
import {
  DeviceCategory,
  DeviceOwnership,
  ProductId,
  TemplateGroup,
  PriceCondition,
} from '@ncs-frontend-monorepo/order';

export enum BasketItemSubType {
  NET_TV_INTERNET = 'NET_TV_INTERNET',
  NET_TV_APP = 'NET_TV_APP',
  NET_TV_CABLE = 'NET_TV_CABLE',
}

export enum BasketItemType {
  TOTAL = 'TOTAL',
  HOUSE_CONNECTION = 'HOUSE_CONNECTION',
  SUB_TOTAL = 'SUB_TOTAL',
  INTERNET = 'INTERNET',
  TELEPHONY = 'TELEPHONY',
  COM_CENTER_BASIC = 'COM_CENTER_BASIC',
  NET_SERVICE = 'NET_SERVICE',
  INSTALLATION_SERVICE = 'INSTALLATION_SERVICE',
  IAD_DEVICE = 'IAD_DEVICE',
  ONT_DEVICE = 'ONT_DEVICE',
  PROVISIONING = 'PROVISIONING',
  DEVICE_SHIPPING = 'DEVICE_SHIPPING',
  TV = 'TV',
  TV_DEVICE = 'TV_DEVICE',
  TV_DEVICE_BYOD = 'TV_DEVICE_BYOD',
  TV_SHIPPING = 'TV_SHIPPING',
}

export enum BasketPositionType {
  TOTAL = 'TOTAL',
  HOUSE_CONNECTION = 'HOUSE_CONNECTION',
  PRODUCT = 'PRODUCT',
  OPTIONS = 'OPTIONS',
  DEVICE = 'DEVICE',
  PROVISIONING = 'PROVISIONING',
  DEVICE_MULTIPLE = 'DEVICE_MULTIPLE',
}

interface BasketItemPricing {
  monthly: PriceCondition | null;
  onetime: PriceCondition | null;
}

export interface BasketPositionItem {
  type: BasketItemType;
  subtype?: BasketItemSubType;
  pricing: BasketItemPricing;
  name?: string;
  download?: number;
  service?: InstallationService;
  id?: string;
  category?: DeviceCategory;
  ownership?: DeviceOwnership;
}

export type BasketPosition = {
  type: BasketPositionType;
  name?: string;
  templateId?: ProductId;
  templateGroup?: TemplateGroup;
  items: BasketPositionItem[];
};

export type ImmutableBasket = {
  basketId: string;
  basket: BasketPosition[];
};
