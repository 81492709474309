import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { DeviceOwnership } from '../constants/device';
import {
  CheckoutStepId,
  ProductId,
  PropertyOwnerRole,
  ResidentialUnit,
} from '../constants';
import { DeviceSelection } from '../interfaces';

export interface CheckoutSelection {
  templateId?: ProductId | null;
  iadDevice?: {
    id: string;
    ownership: DeviceOwnership;
  } | null;
  ontDevice?: {
    id: string;
    ownership: DeviceOwnership;
  } | null;
  tvDevice?: {
    id: string;
    ownership: DeviceOwnership;
    chipsetId?: DeviceSelection['chipsetId'];
  } | null;
  netTvBox?: string;
  installationService?: string;
  checkedSteps?: CheckoutStepId[];
  propertyOwnerRole?: PropertyOwnerRole | null;
  residentialUnit?: ResidentialUnit | null;
  plansUrl?: string | null;
}

interface CheckoutSelectionState {
  selection: CheckoutSelection;
  update: (selection: CheckoutSelection) => void;
  reset: () => void;
}

export const defaultCheckoutSelection: CheckoutSelection = {
  templateId: null,
  iadDevice: null,
  ontDevice: null,
  tvDevice: null,
  netTvBox: '',
  installationService: '',
  checkedSteps: [],
  propertyOwnerRole: null,
  residentialUnit: null,
  plansUrl: null,
};

export const useCheckoutSelectionStore = create<CheckoutSelectionState>()(
  persist(
    (set) => ({
      selection: defaultCheckoutSelection,
      update: (item) =>
        set((state) => ({
          selection: { ...state.selection, ...item },
        })),
      reset: () => set({ selection: defaultCheckoutSelection }),
    }),
    {
      name: 'checkoutSelection',
      storage: createJSONStorage(() =>
        window['Cookiebot']?.consent?.preferences
          ? localStorage
          : sessionStorage,
      ),
    },
  ),
);
