export enum ExpansionStatus {
  UNKNOWN = 'UNKNOWN',
  FALLBACK = 'FALLBACK',
  NOT_PLANNED = 'NOT_PLANNED',
  AREA_PLANNED = 'AREA_PLANNED',
  PRE_MARKETING = 'PRE_MARKETING',
  UNDER_CONSTRUCTION = 'UNDER_CONSTRUCTION',
  HOMES_PASSED = 'HOMES_PASSED',
  HOMES_PASSED_PLUS = 'HOMES_PASSED_PLUS',
  HOMES_PREPARED = 'HOMES_PREPARED',
  HOMES_READY = 'HOMES_READY',
  HOMES_CONNECTED = 'HOMES_CONNECTED',
  NETWORK_NC = 'NETWORK_NC',
  OUT_OF_AREA = 'OUT_OF_AREA',
  ERROR = 'ERROR',
  PRE_SALE = 'PRE_SALE',
  CABLE = 'CABLE',
  FTTB = 'FTTB',
}

export enum ExpansionAccessType {
  CABLE = 'CABLE',
  FTTH = 'FTTH',
  G_FAST = 'G_FAST',
  VDSL_2_FTTB = 'VDSL_2_FTTB',
  UNKNOWN = 'UNKNOWN',
}
