import { AddressFields } from '../components';

/**
 * Returns all address specific url parameters if they exist.
 */
export const getAddressParams = (): AddressFields | null => {
  if (typeof window === 'undefined') return null;
  const searchParams = new URLSearchParams(window.location.search);

  // get address params from url
  const houseNumber = searchParams.get('houseNumber');
  const street = searchParams.get('street');
  const zipCode = searchParams.get('zipCode');
  const city = searchParams.get('city');

  // check if required params exist
  if (!houseNumber || !street || !zipCode) return null;

  return {
    houseNumber,
    street,
    zipCode,
    city: city ?? '',
  };
};

/**
 * Removes all address specific url parameters from the js state.
 */
export const removeAddressParams = () => {
  if (typeof window === 'undefined') return;
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.delete('houseNumber');
  searchParams.delete('street');
  searchParams.delete('zipCode');
  searchParams.delete('city');
};

/**
 * Creates address url parameters based on a given address.
 */
export const createAddressParams = (address: AddressFields): string => {
  if (!address) return '';

  const { houseNumber, street, zipCode, city } = address;
  if (!houseNumber || !street || !zipCode) return '';

  const params = new URLSearchParams({
    houseNumber,
    street,
    zipCode,
    city,
  });

  return params.toString();
};
