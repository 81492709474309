import { Portfolio } from '../components/AvailabilityCheck/Context';
import { PlanColumnProps } from '../components/PlanColumns';
import {
  Product,
  ProductGroups,
  ProductId,
} from '@ncs-frontend-monorepo/order';
import { ProductModifiers } from '../interfaces/product';
import { DynamicPlan, ProductMap } from '../interfaces/template';
import { getIadData } from './index';
import { getProducts } from './orderAPI';
import { OrderPromotion } from '../components/OrderContext';
import { fiberTechnologies } from './promotion';
import { Technology } from '@ncs-frontend-monorepo/availability';

interface GetTemplateIdProps {
  bandwidth: number;
  isYoung?: boolean;
  withPhone?: boolean;
  withTv?: boolean;
  withCableTV?: boolean;
  ftthPrefix?: string;
}

export const getTemplateId = ({
  bandwidth,
  isYoung = false,
  withPhone = false,
  withTv = false,
  withCableTV = false,
  ftthPrefix = '',
}: GetTemplateIdProps): ProductId => {
  return `${ftthPrefix ? ftthPrefix : 'netspeed'}${isYoung ? '-young' : ''}${
    withPhone ? '-phone' : ''
  }${withTv ? '-tv' : ''}${
    withCableTV ? '-cable' : ''
  }-${bandwidth}` as ProductId;
};

export const getPlan = (
  templateId: string,
  visiblePlans: PlanColumnProps[],
  dynamicPlans: ProductMap<DynamicPlan>,
) => {
  let plan: PlanColumnProps = visiblePlans.find(
    (p: PlanColumnProps) => p.id === templateId,
  );

  if (plan) return plan;

  Object.keys(dynamicPlans).some((key) => {
    const dynamicPlan = dynamicPlans[key];
    Object.keys(dynamicPlan).some((key) => {
      const dynamicProduct = dynamicPlan[key];
      if (dynamicProduct.id === templateId) {
        plan = dynamicProduct;
        return true;
      }
    });
    return !!plan;
  });

  return plan;
};

interface GetProductForPlanColumnProps {
  templateId: ProductId;
  technology: Technology;
  iadSlug: string;
  bandwidth: number;
  portfolio?: Portfolio;
}

export const getProductForPlanColumn = async ({
  templateId,
  technology,
  iadSlug,
  bandwidth,
  portfolio = 'FIBER',
}: GetProductForPlanColumnProps): Promise<PlanColumnProps> =>
  await Promise.all([
    getProducts({ templateId, technology }),
    getIadData(iadSlug),
  ]).then(([template, iadContent]) => {
    const { withPhone, withTv, withCableTV } = getPlanParts(templateId);
    const modifiers = getModifier({ withTv, withCableTV, withPhone });
    return {
      ...template,
      iadDeviceInfo: iadContent.content.props,
      templatePageLink: buildPlanDetailPageUrl(
        bandwidth,
        templateId,
        portfolio,
        modifiers,
      ),
    };
  });

// todo update to product.groups.includes(ProductGroups.FIBER)
export const isFiberPlan = (technology: Technology): boolean =>
  fiberTechnologies.includes(technology);

export const isYoungPlan = (groups: Product['groups']): boolean =>
  groups.includes(ProductGroups.YOUNG);

export const isCombiPlan = (groups: Product['groups']): boolean =>
  groups.includes(ProductGroups.COMBINED);

const getBandwidthFromTemplateId = (templateId: ProductId): number =>
  +templateId.match(/(\d+)(?!.*\d)/g)?.[0] || 100;

const getFTTHPrefixFromTemplateId = (templateId: ProductId): string =>
  templateId?.startsWith('ftth') ? templateId.substring(0, 20) : '';

export const getPlanParts = (templateId: ProductId): GetTemplateIdProps => {
  return {
    isYoung: templateId?.includes('young'),
    withPhone: templateId?.includes('phone'),
    withTv: templateId?.includes('tv'),
    withCableTV: templateId?.includes('cable'),
    bandwidth: getBandwidthFromTemplateId(templateId),
    ftthPrefix: getFTTHPrefixFromTemplateId(templateId),
  };
};

export const buildPlanDetailPageUrl = (
  bandWidth: number,
  templateId: ProductId,
  portfolio: Portfolio,
  modifier: ProductModifiers,
): string => {
  const { isYoung, withPhone, withTv } = getPlanParts(templateId);
  const url = `/privatkunden/${portfolio === 'FIBER' ? 'glasfaser' : 'dsl'}`;

  if (isYoung) {
    return (
      url +
      `/young/netspeed-young-${bandWidth}/${
        modifier === ProductModifiers.withoutModifier ? '' : `#${modifier}`
      }`
    );
  }

  if (!withTv && !withPhone) {
    return url + `/tarife/netspeed-${bandWidth}`;
  }

  if (withTv && withPhone) {
    return url + `/telefon-tv/kombi-${bandWidth}`;
  }

  if (withTv && !withPhone) {
    return url + `/tv/kombi-${bandWidth}`;
  }

  if (withPhone && !withTv) {
    return url + `/telefon/kombi-${bandWidth}`;
  }
};

interface BuildPlanOverviewUrlProps {
  isYoung: boolean;
  portfolio: Portfolio;
  productModifier: ProductModifiers;
  isCableTV?: boolean;
}
export const buildPlanOverviewUrl = ({
  isYoung,
  portfolio,
  productModifier,
  isCableTV = false,
}: BuildPlanOverviewUrlProps) => {
  const basePath = process.env.NEXT_PUBLIC_SITE_PREFIX;
  const portfolioPath = portfolio === 'FIBER' ? 'glasfaser' : 'dsl';

  if (isYoung) {
    return `${basePath}/${portfolioPath}/young/#${productModifier}`;
  }

  switch (productModifier) {
    case ProductModifiers.withoutAll:
      return `${basePath}/${portfolioPath}/tarife/#plans`;
    case ProductModifiers.withoutPhone:
      return isCableTV
        ? `${basePath}/fernsehen/nettv-tv-kabel/angebot-${portfolioPath}/#${ProductModifiers.withoutPhone}`
        : `${basePath}/${portfolioPath}/tv/#plans`;
    case ProductModifiers.withoutTv:
      return `${basePath}/${portfolioPath}/telefon/#plans`;
    default:
      return isCableTV
        ? `${basePath}/fernsehen/nettv-tv-kabel/angebot-${portfolioPath}/#plans`
        : `${basePath}/${portfolioPath}/telefon-tv/#plans`;
  }
};

export const getModifier = ({
  withPhone,
  withTv,
  withCableTV,
}: {
  withPhone: boolean;
  withTv: boolean;
  withCableTV: boolean;
}): ProductModifiers => {
  if (!withPhone && !withCableTV && !withTv) return ProductModifiers.withoutAll;
  if (!withPhone) return ProductModifiers.withoutPhone;
  if (!withTv && !withCableTV) return ProductModifiers.withoutTv;
  return ProductModifiers.withoutModifier;
};

export const getPortfolioUrl = ({
  templateId,
  portfolio,
}: {
  templateId: ProductId;
  portfolio: Portfolio;
}) => {
  const { isYoung, withCableTV, withPhone, withTv } = getPlanParts(templateId);
  const productModifier = getModifier({ withCableTV, withPhone, withTv });
  return buildPlanOverviewUrl({
    isYoung,
    productModifier,
    portfolio,
  });
};

export const getOrderPromotionTechnologyByBandwidth = ({
  promotions,
  bandwidth,
}: {
  promotions: OrderPromotion[];
  bandwidth: number;
}) =>
  promotions
    ?.sort((a, b) => (a.maxDownload < b.maxDownload ? -1 : 1))
    ?.filter((promotion) => promotion.maxDownload >= bandwidth)?.[0]
    ?.technology || Technology.FTTH;

export const getPortfolioByPromotionBandwidth = ({
  promotions,
  bandwidth,
}: {
  promotions: OrderPromotion[];
  bandwidth: number;
}) =>
  fiberTechnologies.includes(
    getOrderPromotionTechnologyByBandwidth({ promotions, bandwidth }),
  )
    ? 'FIBER'
    : 'CLASSIC';
