export enum ProductModifiers {
  withoutModifier = 'withoutModifier',
  withoutPhone = 'withoutPhone',
  withoutTv = 'withoutTv',
  withoutAll = 'withoutAll',
}

export enum ProductGroups {
  INTERNET = 'INTERNET',
  TELEPHONY = 'TELEPHONY',
  TV = 'TV',
  TV_INTERNET = 'TV_INTERNET',
  TV_CABLE = 'TV_CABLE',
  TV_APP = 'TV_APP',
  TV_STANDALONE = 'TV_STANDALONE',
  TV_OPTION = 'TV_OPTION',
  COMBINED = 'COMBINED',
  CAMPAIGN = 'CAMPAIGN',
  FLEX = 'FLEX',
  FLEX_VARIANT = 'FLEX_VARIANT',
  FTTH_FACTORY = 'FTTH_FACTORY',
  FTTH_FACTORY_2023_09 = 'FTTH_FACTORY_2023_09',
  CHECK24 = 'CHECK24',
  YOUNG = 'YOUNG',
  NEW_CUSTOMER = 'NEW_CUSTOMER',
  EXISTING_CUSTOMER = 'EXISTING_CUSTOMER',
  FIBER = 'FIBER',
}

export const INTERNET_PHONE_OPTION = [
  ProductGroups.INTERNET,
  ProductGroups.TELEPHONY,
];

export const INTERNET_OPTION = [ProductGroups.INTERNET];
