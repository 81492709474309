import {
  AvailabilityTypes,
  Portfolio,
} from '../components/AvailabilityCheck/Context';
import { BasketPosition } from './basket';
import { OrderRequest, OrderResponse } from './index';
import {
  ExpansionStatus,
  WholeBuy,
  Technology,
  NetTvType,
} from '@ncs-frontend-monorepo/availability';
import { ProductId } from '@ncs-frontend-monorepo/order';

export interface PageEvent {
  event: string;
  page: string;
}

export interface Transaction extends OrderRequest, OrderResponse {}

export interface TransactionEvent extends Transaction {
  event: 'transaction';
}

export interface FtthTransaction {
  obst: string;
  basket: BasketPosition[];
}

export interface FtthTransactionEvent extends FtthTransaction {
  event: 'transaction_ftth';
}

export interface ContactFormEvents {
  event:
    | 'contact-form-submit'
    | 'contact-form-error'
    | 'contact-form-submission';
  contactType: 'existing' | 'new';
}

export interface ProspectRegistrationEvent {
  event: 'prospect_registration';
  operation: 'optIn' | 'optOut';
  status: 'success' | 'error';
}

export interface TariffAdvisor {
  event: 'tariffAdvisor';
  action: 'result';
  templateId?: ProductId;
  alternativeId?: ProductId;
  fallbackId?: ProductId;
}

export interface PODEvents {
  event: 'gee_selection';
  gee_input: string;
}

export enum DataLayerEvents {
  PageView = 'pageview',
  Open = 'verfuegbarkeitscheck-oeffnen',
  Close = 'verfuegbarkeitscheck-schließen',
  Tooltip = 'verfuegbarkeitscheck-info',
  LoginLink = 'verfuegbarkeitscheck-kundenwelt',
  Check = 'verfuegbarkeitscheck-pruefen',
  CheckSmall = 'verfuegbarkeitscheck-portfolio-pruefen',
  Result = 'verfuegbarkeitscheck-result',
  ResultSmall = 'verfuegbarkeitscheck-portfolio-result',
  ResultPreselect = 'verfuegbarkeitscheck-result-preselect',
  ResultMandantMismatch = 'verfuegbarkeitscheck-result-falscher-mandant',
  ChangeAddress = 'verfuegbarkeitscheck-adresse-aendern',
  PlanLink = 'verfuegbarkeitscheck-zu-den-tarifen',
  ContactLink = 'verfuegbarkeitscheck-kontakt-aufnehmen',
  ExpansionLink = 'verfuegbarkeitscheck-ausbaugebiete',
  MandantMismatchLink = 'verfuegbarkeitscheck-mandant-wechsel',
  PresaleSelect = 'verfuegbarkeitscheck-presale-vorbestellen',
  PresaleAlternative = 'verfuegbarkeitscheck-presale-alternative',
  RegisterLink = 'verfuegbarkeitscheck-registrieren',
  PreorderLink = 'verfuegbarkeitscheck-vorbestellen',
  PortfolioSwitch = 'verfuegbarkeitscheck-portfolio-switch',
}

export enum AvailabilityStatus {
  Available = 'verfuegbar',
  AvailablePresale = 'verfuegbar-mit-presale',
  PresaleOnly = 'presale-verfuegbar',
  NotAvailable = 'nicht-verfuegbar',
  IndividualCheck = 'individuelle-pruefung',
  Alternative = 'alternative-verfuegbar',
}

export interface EventAvailabilityOpen {
  event: DataLayerEvents.Open;
  checkStatus: 'bereits-geprueft' | 'nicht-geprueft';
  checkSource: AvailabilityTypes;
  requestedDownload?: number;
  templateId?: ProductId;
}

export interface EventAvailabilityClose {
  event: DataLayerEvents.Close;
  checkSource: AvailabilityTypes;
}

export interface EventAvailabilityTooltip {
  event: DataLayerEvents.Tooltip;
  checkSource: AvailabilityTypes;
}

export interface EventAvailabilityLogin {
  event: DataLayerEvents.LoginLink;
  checkSource: AvailabilityTypes;
  targetPage: string;
}

export interface EventAvailabilityCheck {
  event: DataLayerEvents.Check | DataLayerEvents.CheckSmall;
  checkSource: AvailabilityTypes;
  portfolio?: Portfolio;
  zipCode: string;
}

export interface EventAvailabilityResult {
  event:
    | DataLayerEvents.Result
    | DataLayerEvents.ResultPreselect
    | DataLayerEvents.ResultSmall
    | DataLayerEvents.ResultMandantMismatch;
  status: AvailabilityStatus;
  expansionStatus?: ExpansionStatus;
  checkSource: AvailabilityTypes;
  checkStatus: 'bereits-geprueft' | 'nicht-geprueft';
  zipCode: string;
  maxDownload: number;
  technology?: Technology; // technology only exists on checkout
  portfolio?: Portfolio;
  presaleAvailability?: boolean;
  plannedAvailabilityDate?: string;
  tvPromotions?: Array<keyof typeof NetTvType>;
  templateId?: ProductId; // templateId only exists on checkout
  requestedDownload?: number;
  wholebuy?: WholeBuy | null;
}

export interface EventAvailabilityChangeAddress {
  event: DataLayerEvents.ChangeAddress;
  status: AvailabilityStatus;
  checkSource: AvailabilityTypes;
  zipCode: string;
  maxDownload: number;
  expansionStatus?: ExpansionStatus;
}

export interface EventAvailabilityLinks {
  event:
    | DataLayerEvents.PlanLink
    | DataLayerEvents.ContactLink
    | DataLayerEvents.PreorderLink
    | DataLayerEvents.RegisterLink
    | DataLayerEvents.ExpansionLink
    | DataLayerEvents.MandantMismatchLink;
  status: AvailabilityStatus;
  checkSource: AvailabilityTypes;
  expansionStatus?: ExpansionStatus;
  zipCode: string;
  portfolio?: Portfolio;
  maxDownload: number;
  targetPage: string;
}

export interface EventAvailabilityPresaleSelect {
  event: DataLayerEvents.PresaleSelect;
  status: AvailabilityStatus;
  expansionStatus?: ExpansionStatus;
  checkSource: AvailabilityTypes;
  zipCode: string;
  maxDownload: number;
  plannedAvailabilityDate: string;
}

export interface EventAvailabilityPresaleAlternative {
  event: DataLayerEvents.PresaleAlternative;
  status: AvailabilityStatus;
  checkSource: AvailabilityTypes;
  zipCode: string;
  maxDownload: number;
  alternativeDownload: number;
}

export interface EventAvailabilityPortfolioSwitch {
  event: DataLayerEvents.PortfolioSwitch;
  checkSource: AvailabilityTypes;
  requestedDownload: number;
  templateId: ProductId;
}

export type AvailabilityEvents =
  | EventAvailabilityOpen
  | EventAvailabilityClose
  | EventAvailabilityTooltip
  | EventAvailabilityLogin
  | EventAvailabilityCheck
  | EventAvailabilityResult
  | EventAvailabilityChangeAddress
  | EventAvailabilityLinks
  | EventAvailabilityPresaleSelect
  | EventAvailabilityPresaleAlternative
  | EventAvailabilityPortfolioSwitch;
