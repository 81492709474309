import { CheckoutStepId } from '../constants';

export * from './devices';
export * from './products';

export interface CheckoutSteps {
  id: CheckoutStepId;
  title: string;
  headline: string;
  buttonText: string;
  error?: string;
}

export interface PriceInfo {
  allowWithoutRuntime?: boolean;
  price: number;
  specialOfferPrice: number | null;
  specialOfferDuration: number | null;
  runtime?: number | null;
}
