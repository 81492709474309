import { GeeStatus, Technology } from '@ncs-frontend-monorepo/availability';
import {
  AccessType,
  AvailabilityStatus,
  ExpansionAccessType,
  ExpansionStatus,
  FTTHPresalesInformation,
} from '@ncs-frontend-monorepo/availability';
import { Product } from '@ncs-frontend-monorepo/order';

export const isBandwidthAvailable = (
  requestedBandwidth: number,
  ftthPresalesInformation: FTTHPresalesInformation,
) =>
  ftthPresalesInformation &&
  ftthPresalesInformation.isOffered &&
  ftthPresalesInformation.minAvailableDownload <= requestedBandwidth &&
  ftthPresalesInformation.maxAvailableDownload >= requestedBandwidth;

export const existsPlanWithinBandwidthRange = (
  plans: Product[],
  ftthPresalesInformation: FTTHPresalesInformation,
) =>
  ftthPresalesInformation &&
  ftthPresalesInformation.isOffered &&
  plans.some(
    (plan) =>
      plan.internet.download >= ftthPresalesInformation.minAvailableDownload &&
      plan.internet.download <= ftthPresalesInformation.maxAvailableDownload,
  );

export const isFTTHOffered = (
  expansionStatus: string,
  alternativeProductAvailability: string,
) =>
  (expansionStatus === ExpansionStatus.PRE_MARKETING ||
    expansionStatus === ExpansionStatus.UNDER_CONSTRUCTION) &&
  alternativeProductAvailability === AvailabilityStatus.NOT_AVAILABLE;

/**
 * Returns true if the given geeStatus is granted and the geeValidFrom is at most 3 years in the past.
 * @param {GeeStatus} geeStatus - Status to be checked.
 * @param {string | null} geeValidFrom - Date from when the status is valid, in 'dd.mm.yyyy' format.
 * @returns {boolean} True if the status is granted and the date is within the past three years.
 */
export const hasGrantedGee = (
  geeStatus: GeeStatus,
  geeValidFrom: string | null,
): boolean => {
  if (geeStatus !== GeeStatus.ERTEILT || !geeValidFrom) return false;

  const dateParts = geeValidFrom.split('.').map(Number);
  if (dateParts.length !== 3 || dateParts.some(isNaN)) return false;

  const [day, month, year] = dateParts;
  const geeValidFromDate = new Date(year, month - 1, day);

  if (isNaN(geeValidFromDate.getTime())) return false;

  const threeYearsAgo = new Date();
  threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);

  return geeValidFromDate >= threeYearsAgo;
};

export const expansionAccessTypeToTechnology = (
  expansionAccessType: ExpansionAccessType,
): Technology => {
  switch (expansionAccessType) {
    case ExpansionAccessType.CABLE:
      return Technology.CABLE;
    case ExpansionAccessType.G_FAST:
      return Technology.GFAST;
    case ExpansionAccessType.VDSL_2_FTTB:
      return Technology.VDSL2_FTTB;
    default:
      return Technology.FTTH;
  }
};

// send mergedAccessType to BE - BE need this part
// if UNKNOWN mergedAccessType send FTTH as technology
export const mergedAccessTypeToTechnology = (
  mergedAccessType: AccessType,
): Technology => {
  switch (mergedAccessType) {
    case AccessType.CABLE:
      return Technology.CABLE;
    case AccessType.G_FAST:
      return Technology.GFAST;
    case AccessType.VDSL_2_FTTB:
      return Technology.VDSL2_FTTB;
    case AccessType.FTTH_BSA_L2:
      return Technology.FTTH_BSA_L2;
    case AccessType.FTTH_BSA_L2_DG:
      return Technology.FTTH_BSA_L2_DG;
    default:
      return Technology.FTTH;
  }
};
