import {
  DeviceCategory,
  ProductId,
  TemplateGroup,
} from '@ncs-frontend-monorepo/order';
import { BasketItemType, BasketPositionType } from '../interfaces/basket';
import {
  ExpansionStatus,
  NetTvType,
} from '@ncs-frontend-monorepo/availability';
import { Technology } from '@ncs-frontend-monorepo/availability';

export const BANDWIDTHS = [50, 100, 250, 500, 1000];

export const MAX_VISIBLE_PLAN = 250;
export const MAX_VISIBLE_PLAN_FTTH = 1000;

export const PLANNED_FTTH_STATUS = [
  ExpansionStatus.AREA_PLANNED,
  ExpansionStatus.UNDER_CONSTRUCTION,
  ExpansionStatus.PRE_MARKETING,
];

// Time after which the application can re-request the CMS for revalidation (stale-while-revalidate)
export const REVALIDATON_DURATION = 30;

// Time window in which presale promotions will be directly booked instead of pre-ordering
export const MIN_PRESALE_BOOKING_WEEKS = 5;

export const FTTH_FACTORY_GROUPS = [
  TemplateGroup.FTTH_FACTORY_2023_09,
  TemplateGroup.FTTH_FACTORY_2023_09_PHONE,
  TemplateGroup.FTTH_FACTORY_2023_09_TV,
  TemplateGroup.FTTH_FACTORY_2023_09_PHONE_TV,
];

export const YOUNG_GROUPS = [
  TemplateGroup.NETSPEED_YOUNG,
  TemplateGroup.NETSPEED_YOUNG_KOMBI_PHONE,
  TemplateGroup.NETSPEED_YOUNG_KOMBI_TV,
  TemplateGroup.NETSPEED_YOUNG_KOMBI_PHONE_TV,
];

export const HIGHLIGHTED_PROPOSALS = [
  'GLASFASERBONUS_2022_10',
  'EXTRA_BONUS_2022_11',
];

export const FTTH_PROPOSALS = ['GLASFASERBONUS_2022_10'];

export const HIGHLIGHTED_PRETEXT_FTTH =
  'An deiner Adresse surfst du in unserem Glasfasernetz. ';

export const FALLBACK_BANDWITH = [175, 25];

export const DEFAULT_FIBER_TECHNOLOGY = Technology.FTTH;
export const DEFAULT_CLASSIC_TECHNOLOGY = Technology.CABLE;

export const GENERIC_TEXT = {
  basic_router: 'Basic WLAN-Router',
  premium_router: 'Premium WLAN-Router',
};

export type KEY_TEXT = {
  [key in
    | BasketPositionType
    | BasketItemType
    | DeviceCategory
    | NetTvType]?: string;
};
export const SHOPPING_CART_ITEM: KEY_TEXT = {
  [BasketPositionType.TOTAL]: 'Gesamtpreis',
  [BasketPositionType.HOUSE_CONNECTION]: 'Glasfaserhausanschluss',
  [BasketPositionType.PRODUCT]: 'Paket',
  [BasketPositionType.OPTIONS]: 'Zusatzoptionen',
  [BasketPositionType.DEVICE]: 'Endgerät',
  [BasketPositionType.DEVICE_MULTIPLE]: 'Endgeräte',
  [BasketPositionType.PROVISIONING]: 'Bereitstellung',
};

export const PRICE_POSITION: KEY_TEXT = {
  TOTAL: 'Gesamtpreis',
  SUB_TOTAL: 'Zwischensumme',
  HOUSE_CONNECTION: 'Eigentümeranteil',
  INTERNET: 'Internet-Tarif',
  TELEPHONY: 'Telefon-Flat',
  NET_TV_APP: 'NetTV App',
  NET_TV_INTERNET: 'NetTV über Internet',
  COM_CENTER_BASIC: 'Mail & Cloud Basic',
  NET_SERVICE: 'WLAN Service Plus',
  INSTALLATION_SERVICE: 'Einrichtungsservice',
  IAD_DEVICE: 'Router',
  ONT_DEVICE: 'Glasfaser-Modem',
  TV_DEVICE: 'CI+ Modul',
  TV_DEVICE_BYOD: 'CI+ Modul',
  BASIC: GENERIC_TEXT.basic_router,
  PREMIUM: GENERIC_TEXT.premium_router,
  PROVISIONING: 'Bereitstellungskosten',
  DEVICE_SHIPPING: 'Versand Router',
  TV_SHIPPING: 'Versand NetTV-Box',
  NET_TV_CABLE: 'Versand',
};

export const PRICE_POSITION_DESCRIPTION: KEY_TEXT = {
  TOTAL: 'inkl. 19 % MwSt.',
  INTERNET: 'Internet-Tarif',
  TELEPHONY: 'Flatrate ins deutsche Festnetz',
  NET_TV_APP:
    'Erlebe rund 80 Sender auf deinem Smartphone, Tablet und Computer.',
  NET_TV_INTERNET: 'Erlebe rund 100 Sender und zeitversetztes Fernsehen.',
  COM_CENTER_BASIC: '1 GB Postfachspeicher und 1 GB Cloudspeicher',
  NET_SERVICE: 'Dauerhaft stabiles und sicheres WLAN.',
  TV_DEVICE_DESCRIPTION: 'Für Fernseher mit CI+ Modul',
  TV_DEVICE_BYOD_DESCRIPTION: 'Receiver oder CI+ Modul',
};

export const PRICE_POSITION_TOOLTIP: KEY_TEXT = {
  NET_TV_CABLE: 'Die Versandkosten fallen für den Versand der Smartcard an.',
};

export interface TechnologyInfo {
  technologies: Technology[];
  name: string;
  url: {
    netcologne: string;
    netaachen: string;
  };
}

export const DEFAULT_PREMIUM_MIN_BANDWIDTH = 175;
export const technologyInfo: TechnologyInfo[] = [
  {
    technologies: [
      Technology.DSL_ANALOG,
      Technology.ADSL2_IP,
      Technology.DSL_ISDN,
    ],
    name: 'DSL ohne Splitter',
    url: {
      netcologne:
        'https://www.netcologne.de/privatkunden/hilfe/eigenen-router-einrichten/dsl-ohne-splitter',
      netaachen:
        'https://www.netaachen.de/privatkunden/hilfe/eigenen-router-einrichten/dsl-ohne-splitter',
    },
  },
  {
    technologies: [Technology.VDSL_BSA_L2, Technology.VDSL_BSA_L3],
    name: 'VDSL-Bitstream',
    url: {
      netcologne:
        'https://www.netcologne.de/privatkunden/hilfe/eigenen-router-einrichten/vdsl-bitstream',
      netaachen:
        'https://www.netaachen.de/privatkunden/hilfe/eigenen-router-einrichten/vdsl-bitstream',
    },
  },
  {
    technologies: [Technology.CABLE],
    name: 'Cable',
    url: {
      netcologne:
        'https://www.netcologne.de/privatkunden/hilfe/eigenen-router-einrichten/cable',
      netaachen:
        'https://www.netaachen.de/privatkunden/hilfe/eigenen-router-einrichten/cable',
    },
  },
  {
    technologies: [Technology.FTTH],
    name: 'FTTH',
    url: {
      netcologne:
        'https://www.netcologne.de/privatkunden/hilfe/eigenen-router-einrichten/ftth',
      netaachen:
        'https://www.netaachen.de/privatkunden/hilfe/eigenen-router-einrichten/ftth',
    },
  },
  {
    technologies: [Technology.FTTH_BSA_L2, Technology.FTTH_BSA_L2_DG],
    name: 'FTTH',
    url: {
      netcologne:
        'https://www.netcologne.de/privatkunden/hilfe/eigenen-router-einrichten/ftth-bsa/',
      netaachen:
        'https://www.netaachen.de/privatkunden/hilfe/eigenen-router-einrichten/ftth-bsa/',
    },
  },
  {
    technologies: [Technology.GFAST],
    name: 'G.Fast',
    url: {
      netcologne:
        'https://www.netcologne.de/privatkunden/hilfe/eigenen-router-einrichten/gfast',
      netaachen:
        'https://www.netaachen.de/privatkunden/hilfe/eigenen-router-einrichten/gfast',
    },
  },
  {
    technologies: [Technology.VDSL2_FTTB, Technology.FTTC],
    name: 'VDSL',
    url: {
      netcologne:
        'https://www.netcologne.de/privatkunden/hilfe/eigenen-router-einrichten/vdsl',
      netaachen:
        'https://www.netaachen.de/privatkunden/hilfe/eigenen-router-einrichten/vdsl',
    },
  },
];

export const INSTALLATION_SERVICE_IDS = ['NetStart', 'NetStartPlus'];

export const MODULE_SPACING = 6;

export const BESTSELLER_TEXT = 'Bestseller';
export const TOPPRICETEXT = 'Top Preis/Leistung';

export const THANK_YOU_PAGE_IDS = {
  NetCologne: 1446,
  NetAachen: 1450,
};

export const TV_THANK_YOU_PAGE_IDS = {
  NetCologne: 1447,
  NetAachen: 1451,
};

export const FTTH_PLAN_PAGE_IDS = {
  NetCologne: 1523,
  NetAachen: 1524,
};

export const FTTH_THANK_YOU_PAGE_IDS = {
  UNDER_CONSTRUCTION: {
    NetCologne: 1482,
    NetAachen: 1486,
  },
  PRE_MARKETING: {
    NetCologne: 1480,
    NetAachen: 1485,
  },
};

export const CANCELLATION_SUCCESS_PAGE_IDS = {
  NetCologne: 1497,
  NetAachen: 1498,
};

export const PRODUCTS_WITHOUT_PORTFOLIO = [
  ProductId.TV_CABLE,
  ProductId.TV_CABLE_EXISTING_CUSTOMER,
  ProductId.TV_CABLE_GWG_NEUSS,
  ProductId.TV_CABLE_HD,
  ProductId.TV_CABLE_PREMIUM_HD,
  ProductId.TV_CABLE_FAMILY_HD,
];

export const HOME_ID = 'homeId';
