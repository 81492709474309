import cn from 'classnames';
import Image from 'next/image';
import {
  Button,
  ButtonProps,
  Grid,
  ImageConfigTypes,
  ImageSrc,
  optimizedImageSrc,
  Picture,
  RichText,
  Spacer,
  Text,
  TextElements,
  useBreakpoints,
} from 'dss-ui-library';
import { FC, lazy, Suspense } from 'react';
import { ContentType } from '../../interfaces';
import styles from './Banner.module.scss';
import { ButtonLink } from '../../components';
import { setNextImageSrc } from '../../utils';
import { CounterProps } from '../Counter';
const BannerItem = lazy(() => import('./BannerItem'));

export enum BannerVariant {
  Default = 'default',
  Overlap = 'overlap',
}

export enum BannerDistractionPosition {
  Default = 'bottomCenter',
  TopLeft = 'topLeft',
  TopCenter = 'topCenter',
  TopRight = 'topRight',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight',
}

export enum BannerDistractionMobile {
  MobileLeft = 'mobileLeft',
  MobileRight = 'mobileRight',
}

interface Distraction {
  image: ImageSrc;
  position?: BannerDistractionPosition;
  mobilePosition?: BannerDistractionMobile;
}

export interface BannerItemProps {
  component: ContentType;
  id: number;
  props: CounterProps;
}

interface BannerBasics {
  variant?: BannerVariant;
  layout: 'half-left' | 'half-right' | 'full';
  backgroundColor: 'white' | 'blue' | 'grey';
  backgroundColorMobile: 'white' | 'blue' | 'grey';
  color: 'light' | 'dark';
  colorMobile: 'light' | 'dark';
  headline: string;
  headlineElement: TextElements;
  text: string;
  buttonType: 'primary' | 'primary-blue' | 'secondary';
  image: ImageSrc;
  distraction?: Distraction[];
  useOptimizedImages?: boolean;
  items?: BannerItemProps[];
}

interface BannerButton extends BannerBasics {
  link?: never;
  button?: {
    e2e: string;
    color?: ButtonProps['color'];
    text: string;
    onClick(): void;
  };
}

interface BannerLink extends BannerBasics {
  button?: never;
  link?: {
    url: string;
    text: string;
    target?: string;
    e2e?: string;
  };
}

export type BannerProps = BannerButton | BannerLink;
export const Banner: FC<BannerProps> = ({
  variant = BannerVariant.Default,
  layout,
  backgroundColor,
  backgroundColorMobile,
  color,
  colorMobile,
  headline,
  headlineElement,
  text,
  link,
  buttonType,
  image,
  distraction = [],
  items = [],
  button,
  useOptimizedImages = true,
}) => {
  const { isXS, isS } = useBreakpoints();

  const optimizedImages = optimizedImageSrc({
    imageSrc: image,
    config:
      layout === 'full'
        ? ImageConfigTypes.SliderFullWidth
        : ImageConfigTypes.SliderSquare,
  });

  const distractionElement = distraction?.[0];

  const sticker = (isMobile = true) =>
    distractionElement ? (
      <div
        className={cn(
          styles.bannerDistractionImage,
          styles[distractionElement?.position || ''],
          distractionElement.mobilePosition ===
            BannerDistractionMobile.MobileRight
            ? styles.rightAligned
            : styles.leftAligned,
          {
            [styles.mobilePosition]: isMobile,
          },
        )}
      >
        <Image
          src={distractionElement.image?.src}
          alt={distractionElement.image?.alt || 'Banner Störer'}
          fill
        />
      </div>
    ) : (
      <></>
    );

  return (
    <div
      className={cn(
        styles.banner,
        styles[layout],
        styles[isXS || isS ? backgroundColorMobile : backgroundColor],
        styles[isXS || isS ? colorMobile : color],
        {
          [styles[variant]]: variant !== BannerVariant.Default,
          [styles.withDistraction]: distractionElement,
        },
      )}
    >
      <div
        className={cn(styles.bannerImage, {
          [styles.hasDistraction]: distraction,
        })}
      >
        {sticker(false)}

        <Picture
          {...(useOptimizedImages
            ? setNextImageSrc({ imageSrc: optimizedImages })
            : optimizedImages)}
          includingLength={false}
        />
      </div>

      <Grid className={cn(styles.bannerContent, styles.stretch)}>
        {sticker(true)}
        <Grid.Row className={styles.stretch}>
          <Grid.Column
            className={styles.flex}
            m={layout === 'full' ? 7 : 6}
            offsetStartM={layout === 'half-left' ? 7 : 0}
            vCenter={variant !== BannerVariant.Overlap}
          >
            <Spacer
              r={
                layout === 'half-left' || variant === BannerVariant.Overlap
                  ? 1
                  : 2
              }
              l={layout === 'half-left' ? 2 : 0}
              className={cn(styles.bannerContentSpacer, {
                [styles.withDistraction]: distractionElement,
              })}
              block
            >
              {items.length > 0 ? (
                <Suspense>
                  <BannerItem
                    items={items}
                    text={text}
                    headline={headline}
                    backgroundColor={backgroundColor}
                  />
                </Suspense>
              ) : (
                <>
                  <Spacer b={2} block>
                    <Text
                      element={headlineElement}
                      appearance="t1_2"
                      className={styles.headline}
                    >
                      {headline}
                    </Text>
                  </Spacer>

                  <RichText
                    text={text}
                    onBackground={
                      isXS || isS
                        ? backgroundColorMobile === 'blue'
                          ? 'blue'
                          : 'white'
                        : backgroundColor === 'blue'
                        ? 'blue'
                        : 'white'
                    }
                  />
                </>
              )}

              {button ? (
                <Spacer t={3} block>
                  <Button
                    onClick={button.onClick}
                    variant={
                      buttonType.includes('primary') ? 'primary' : 'secondary'
                    }
                    color={
                      button.color
                        ? button.color
                        : buttonType === 'primary'
                        ? 'red'
                        : backgroundColor === 'blue'
                        ? 'white'
                        : 'blue'
                    }
                    e2e={`banner-button-${button.e2e}`}
                  >
                    {button.text}
                  </Button>
                </Spacer>
              ) : (
                link && (
                  <Spacer t={3} block>
                    <ButtonLink
                      link={link}
                      contactTrack={link.e2e}
                      type={
                        buttonType === 'primary'
                          ? 'primary_red'
                          : backgroundColor === 'blue' &&
                            buttonType === 'primary-blue'
                          ? 'primary_white'
                          : buttonType === 'primary-blue'
                          ? 'primary_blue'
                          : ['white', 'grey'].includes(
                              backgroundColor as string,
                            )
                          ? 'secondary_blue'
                          : 'secondary_white'
                      }
                    >
                      {link.text}
                    </ButtonLink>
                  </Spacer>
                )
              )}
            </Spacer>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Banner;
