import { differenceInWeeks } from 'date-fns';
import { CancellationRequest } from '../components/Forms/Cancellation/cancellationFormModel';
import { ContactView } from '../components/Forms/Contact';
import { ContactNewCustomerRequest } from '../components/Forms/Contact/Views/NewCustomer/model';
import { BasketProduct } from '../components/OrderContext';
import { MIN_PRESALE_BOOKING_WEEKS } from '../constants';
import { AvailabilityLoggingInfo } from '../hooks/useAvailability';
import { OrderRequest, OrderResponse } from '../interfaces';
import { BasketPosition, ImmutableBasket } from '../interfaces/basket';
import {
  ContractSummary,
  ContractSummaryByBasketIdParams,
  ContractSummaryParams,
} from '../interfaces/contractSummary';
import {
  BUSINESS_UNIT,
  businessUnitHeader,
  correlationIdHeader,
  fetcher,
  fetcherBoolean,
  fetcherText,
  getCorrelationId,
  getEnv,
  isNC,
  Mandant,
  removeCorrelationId,
  requestedDownloadHeader,
  userInteractionHeader,
} from '@ncs-frontend-monorepo/utils';
import { ResellerContactRequest } from '../components/Forms/ResellerContact/model';
import {
  CheckoutSelection,
  Product,
  ProductId,
} from '@ncs-frontend-monorepo/order';
import {
  AvailabilityResponse,
  ERROR_CODES_NO_AVAILABILITY,
  Fallback,
  getFallbackResponse,
  LandlinePromotion,
  shouldShowFallback,
  Technology,
} from '@ncs-frontend-monorepo/availability';

export enum AddressSource {
  local = 'LOCAL',
  national = 'NATIONAL',
  combined = 'NATIONAL_WITH_LOCAL',
}

export enum FetchAddressType {
  streets = 'streets',
  addresses = 'addresses',
}

export interface FetchZipResponse {
  zipCode: string;
  city: string;
}

export interface CheckAvailabilityParams {
  zipCode: string;
  street: string;
  houseNumber: string;
  city?: string;
  loggingInfo?: AvailabilityLoggingInfo;
}

export const orderServiceURL = (): string => getEnv().ORDER_SERVICE_URL;
export const homeIdCheckURL = (): string =>
  `${getEnv().ORDER_SERVICE_URL}/check-home-id`;
export const dssApiURL = (): string => getEnv().PK.DSS_API_URL;

export async function getContractSummary(
  params: ContractSummaryParams,
): Promise<ContractSummary> {
  return await fetcher(`${orderServiceURL()}/contract-summary`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      [correlationIdHeader]: getCorrelationId(),
    },
    body: JSON.stringify(params),
  });
}

export async function getContractSummaryByBasketId({
  params,
  idToken,
}: {
  params: ContractSummaryByBasketIdParams;
  idToken?: string;
}): Promise<ContractSummary> {
  const fileId = await fetcherText(
    `${dssApiURL()}/ftth/basket/contract-summary`,
    {
      method: 'POST',
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'application/json',
        ...(idToken && { Authorization: `Bearer ${idToken}` }),
        [correlationIdHeader]: getCorrelationId(),
      },
      body: JSON.stringify(params),
    },
  );
  return {
    fileId,
  };
}

export interface AvailabilityInfo {
  availablePromotions: LandlinePromotion[] | null;
  presalePromotion: LandlinePromotion | null;
  objectInformation: AvailabilityResponse['objectInformation'] | null;
  tvPromotions: AvailabilityResponse['tvPromotions'] | [];
  address: AvailabilityResponse['address'];
  hasFallback: Fallback | null;
}

export const normalizeAvailability = (
  result: AvailabilityResponse,
): AvailabilityInfo => {
  const { landlinePromotions, tvPromotions, objectInformation, address } =
    result;
  let hasFallback = [Fallback.ADSL, Fallback.TECHNOLOGY].includes(
    result.fallback,
  )
    ? result.fallback
    : null;

  let availablePromotions: LandlinePromotion[] = null;
  let presalePromotion: LandlinePromotion = null;

  if (landlinePromotions.length) {
    const presalePromotionIndex = landlinePromotions.findIndex(
      (promotions) => promotions?.availability === 'PRESALE',
    );

    if (presalePromotionIndex > -1) {
      presalePromotion = result.landlinePromotions[presalePromotionIndex];
      const weeksUntilAvailability = differenceInWeeks(
        new Date(presalePromotion.plannedAvailabilityDate),
        new Date(),
      );

      // If time window of Presale promotion is too long, we must offer existing (available promotion)
      if (weeksUntilAvailability >= MIN_PRESALE_BOOKING_WEEKS) {
        availablePromotions =
          landlinePromotions.filter(
            (_, index) => index !== presalePromotionIndex,
          ) || null;

        // EDGE-Case!!! with Presale and ADSL-fallback, only presale should be available, fallback should be NONE
        if (hasFallback === Fallback.ADSL) {
          availablePromotions = [];
          hasFallback = Fallback.NONE;
        }
      } else {
        availablePromotions = [
          {
            ...presalePromotion,
            plannedAvailabilityDate: null,
            plannedAvailabilityDateDescription: '',
          },
        ];
        presalePromotion = null;
      }
    } else {
      availablePromotions = result.landlinePromotions;
    }
  }

  return {
    availablePromotions,
    presalePromotion,
    tvPromotions,
    objectInformation,
    address,
    hasFallback,
  };
};

export async function checkAvailability({
  zipCode,
  street,
  houseNumber,
  city,
  loggingInfo,
}: CheckAvailabilityParams): Promise<AvailabilityInfo> {
  const { manualChecked = false, requestedDownload } = loggingInfo || {};
  const searchParams = new URLSearchParams({
    zipCode,
    street,
    houseNumber,
    ...(city && { city }),
  });

  const headerContent = {
    [correlationIdHeader]: getCorrelationId(),
    [businessUnitHeader]: BUSINESS_UNIT.PK,
    ...(manualChecked && {
      [userInteractionHeader]: 'true',
      ...(requestedDownload && {
        [requestedDownloadHeader]: String(requestedDownload),
      }),
    }),
  };

  try {
    const response = await fetcher(
      `${getEnv().ORDER_SERVICE_URL}/availability?${searchParams}`,
      {
        headers: headerContent,
      },
    );

    return normalizeAvailability(response);
  } catch (error) {
    if (
      typeof error?.cause !== 'number' ||
      ERROR_CODES_NO_AVAILABILITY.includes(error.cause) ||
      !shouldShowFallback({ zipCode })
    ) {
      return {
        availablePromotions: null,
        presalePromotion: null,
        tvPromotions: null,
        objectInformation: null,
        address: null,
        hasFallback: null,
      };
    }

    const fallback = getFallbackResponse(Technology.CABLE, {
      zipCode,
      street,
      houseNumber,
      city,
    });

    return normalizeAvailability(fallback);
  }
}

export interface GetProductProps {
  templateId: ProductId;
  promotionId?: string;
  withoutRuntime?: boolean;
  maxDownload?: number;
  technology?: Technology;
  zipCode?: string;
  mandant?: Mandant;
  pubId?: string;
}

export async function getProducts({
  templateId,
  promotionId,
  withoutRuntime,
  maxDownload,
  technology,
  zipCode,
  mandant,
  pubId,
}: GetProductProps): Promise<Product> {
  try {
    const searchParams = new URLSearchParams({
      templateId,
      mandant: mandant ? mandant : isNC() ? 'NC' : 'NA',
      ...(promotionId && { promotionId }),
      ...(withoutRuntime !== undefined && {
        withoutRuntime: withoutRuntime ? 'true' : 'false',
      }),
      ...(maxDownload && { maxDownload: String(maxDownload) }),
      ...(technology && { technology }),
      ...(zipCode && { zipCode }),
      ...(pubId && { pubId }),
    });

    return await fetcher(`${orderServiceURL()}/products?${searchParams}`, {
      headers: {
        [correlationIdHeader]: getCorrelationId(),
      },
    });
  } catch {
    return null;
  }
}

export interface GetDevicesProps {
  templateId: ProductId;
}

export async function postBasket(
  order: BasketProduct,
): Promise<BasketPosition[]> {
  return await fetcher(`${orderServiceURL()}/basket`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      [correlationIdHeader]: getCorrelationId(),
    },
    body: JSON.stringify(order),
  });
}

export async function postImmutableBasket(
  basket: BasketProduct,
  userSelection: CheckoutSelection,
): Promise<ImmutableBasket> {
  return await fetcher(`${dssApiURL()}/ftth/basket`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      [correlationIdHeader]: getCorrelationId(),
    },
    body: JSON.stringify({
      templateId: basket.id,
      device: basket.device,
      ontDevice: basket?.ontDevice,
      installationService: basket.installationService || 'none',
      houseSerialNumber: basket.installationAddress.hausLfdnr,
      propertyOwnerRole: userSelection.propertyOwnerRole,
    }),
  });
}

export async function postOrder(order: OrderRequest): Promise<OrderResponse> {
  const response = await fetcher(`${orderServiceURL()}/orders`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      [correlationIdHeader]: getCorrelationId(),
    },
    body: JSON.stringify(order),
  });
  removeCorrelationId();
  return response;
}

export async function postCancellation(
  data: CancellationRequest,
): Promise<boolean> {
  await fetcherBoolean(
    `${orderServiceURL()}/support/cancellationform/anonymous`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        [correlationIdHeader]: getCorrelationId(),
      },
      body: JSON.stringify(data),
    },
  );
  return true;
}

export async function postContact(
  data: ContactNewCustomerRequest,
  type: ContactView,
): Promise<boolean> {
  const url =
    type === ContactView.ExistingCustomer
      ? `${orderServiceURL()}/support/website/contact/customer/existing`
      : `${orderServiceURL()}/support/website/contact/customer/new`;
  await fetcherBoolean(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      [correlationIdHeader]: getCorrelationId(),
    },
    body: JSON.stringify(data),
  });

  return true;
}

export async function postResellerContact(
  data: ResellerContactRequest,
): Promise<boolean> {
  await fetcherBoolean(
    `${orderServiceURL()}/support/website/contact/reseller/new`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        [correlationIdHeader]: getCorrelationId(),
      },
      body: JSON.stringify(data),
    },
  );
  return true;
}
