import { create } from 'zustand';
import { ProductGroups, ProductModifiers } from '../constants';
import { createJSONStorage, persist } from 'zustand/middleware';

interface OrderStoreState {
  modifier: ProductModifiers;
  selectedOptions: ProductGroups[];
  updateModifier: (modifier: ProductModifiers) => void;
  updateSelectedOptions: (option: ProductGroups[]) => void;
  resetModifier: () => void;
}

export const useOrderStore = create<OrderStoreState>()(
  persist(
    (set) => ({
      modifier: ProductModifiers.withoutAll,
      selectedOptions: [],
      updateModifier: (modifier) =>
        set((state) => ({
          ...state,
          modifier,
        })),
      updateSelectedOptions: (selectedOptions) =>
        set((state) => ({
          ...state,
          selectedOptions,
        })),
      resetModifier: () =>
        set({
          modifier: ProductModifiers.withoutAll,
          selectedOptions: [],
        }),
    }),
    {
      name: 'orderStore',
      storage: createJSONStorage(() =>
        window['Cookiebot']?.consent?.preferences
          ? localStorage
          : sessionStorage,
      ),
    },
  ),
);
