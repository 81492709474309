import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import {
  Device,
  OntDevice,
  ProductId,
  SelectedDevice,
  TemplateGroup,
  Product,
} from '@ncs-frontend-monorepo/order';
import { BasketPosition } from '../../interfaces/basket';
import { Address, InstallationService } from '../../interfaces/template';
import { hasGrantedGee, isFTTHOffered } from '../../utils/ftthPreMarketing';
import { checkAvailability } from '../../utils/orderAPI';
import { getMaxDownloads, getTechnologyDownloads } from '../../utils/promotion';
import { Portfolio } from '../AvailabilityCheck/Context';
import { ContactExistingCustomerRequest } from '../Forms/Contact/Views/ExistingCustomer/UploadForm/model';
import { ContactNewCustomerRequest } from '../Forms/Contact/Views/NewCustomer/model';
import { ResellerContactRequest } from '../Forms/ResellerContact/model';
import { TariffAdvisorValues } from '../TariffAdvisor';
import { orderReducer } from './orderReducer';
import { Mandant, storageAvailable } from '@ncs-frontend-monorepo/utils';
import {
  AvailabilityStatus,
  Fallback,
  FTTHPresalesInformation,
  getAddressParams,
  NetTvType,
  ObjectInformation,
  Technology,
} from '@ncs-frontend-monorepo/availability';

export type OrderState = {
  fallback: Fallback;
  maxAvailableDownload: number | null;
  plannedAvailability: {
    maxAvailableDownload: number;
    plannedAvailabilityDate: string;
    plannedAvailabilityDateDescription: string;
  } | null;
  availableTv: Array<keyof typeof NetTvType> | null;
  address: AddressSelection | null;
  basket: BasketProduct | null;
  basketId: string | null;
  shoppingCartData: {
    basketTemplateGroup: TemplateGroup;
    basket: BasketPosition[];
  };
  orderFormData: { [key: string]: string | number | boolean | [] };
  ftthPresalesInformation: FTTHPresalesInformation | null;
  availableProductOptions: AvailableProductOptions | null;
  isYoung: boolean;
  promotions: OrderPromotion[];
  products:
    | {
        [key in keyof typeof ProductId]?: Product;
      }
    | null;
  formData: FormData;
  inkassoTypeValidFrom: string | null;
};

export type ContactExistingCustomerFormData = Omit<
  ContactExistingCustomerRequest,
  'file-upload-ids'
>;

interface FormData {
  contactNewCustomerForm?: ContactNewCustomerRequest;
  contactExistingCustomerForm?: ContactExistingCustomerFormData;
  tariffAdvisorForm?: TariffAdvisorValues;
  resellerContact?: ResellerContactRequest;
  isCustomer?: boolean;
}

export interface AvailableProductOptions {
  devices: Device[];
  ontDevices: OntDevice[];
  installationServices: InstallationService[];
}

export interface AddressSelection {
  zipCode: string;
  street: string;
  city: string;
  district?: string;
  houseNumber: string;
  houseSeqNumber?: number;
  mandant?: Mandant;
}

export const initialState: OrderState = {
  fallback: Fallback.NONE,
  address: null,
  maxAvailableDownload: null,
  availableTv: null,
  plannedAvailability: null,
  basket: null,
  basketId: null,
  shoppingCartData: null,
  orderFormData: null,
  ftthPresalesInformation: null,
  availableProductOptions: null,
  isYoung: false,
  promotions: null,
  products: null,
  formData: null,
  inkassoTypeValidFrom: null,
};

export interface BasketProduct
  extends Omit<
    Product,
    | 'priceInfo'
    | 'name'
    | 'shortname'
    | 'installationServices'
    | 'group'
    | 'isWithoutRuntimeAvailable'
    | 'pricing'
    | 'runtime'
    | 'devices'
    | 'ontDevices'
    | 'displayProposals'
    | 'groups'
  > {
  group: TemplateGroup;
  device?: SelectedDevice;
  ontDevice?: SelectedDevice;
  tvDevice?: SelectedDevice;
  installationAddress: Address;
  installationService?: string;
  plannedAvailabilityDate?: string;
  plannedAvailabilityDateDescription?: string;
  pubId?: string;
}

export interface OrderPromotion {
  technology: Technology;
  maxDownload: number;
  portfolio: Portfolio;
}

export enum OrderActionType {
  SET_ADDRESS = 'SET_ADDRESS',
  SET_AVAILABILITY = 'SET_AVAILABILITY',
  ADD_PRODUCT_TO_BASKET = 'ADD_PRODUCT_TO_BASKET',
  SAVE_ORDER_FORM_DATA = 'SAVE_ORDER_FORM_DATA',
  SET_USER_SELECTION = 'SET_USER_SELECTION',
  CLEAR_BASKET = 'CLEAR_BASKET',
  CLEAR_ORDER = 'CLEAR_ORDER',
  CLEAR_ORDER_FORM_DATA = 'CLEAR_ORDER_FORM_DATA',
  SET_FTTH_PRESALES_INFORMATION = 'SET_FTTH_PRESALES_INFORMATION',
  SET_INKASSO_TYPE_VALID_FROM = 'SET_INKASSO_TYPE_VALID_FROM',
  SET_AVAILABLE_PRODUCT_OPTIONS = 'SET_AVAILABLE_PRODUCT_OPTIONS',
  SET_IS_YOUNG = 'SET_IS_YOUNG',
  SET_BASKET_ID = 'SET_BASKET_ID',
  SET_SHOPPING_CART_DATA = 'SET_SHOPPING_CART_DATA',
  ADD_PRODUCT = 'ADD_PRODUCT',
  SAVE_FORM_DATA = 'SAVE_FORM_DATA',
}

type ActionSetAddress = {
  type: OrderActionType.SET_ADDRESS;
  payload: {
    address: OrderState['address'];
  };
};

type ActionSetAvailability = {
  type: OrderActionType.SET_AVAILABILITY;
  payload: {
    maxAvailableDownload: OrderState['maxAvailableDownload'];
    plannedAvailability?: OrderState['plannedAvailability'];
    availableTv?: OrderState['availableTv'];
    promotions?: OrderState['promotions'];
    address?: OrderState['address'];
    fallback?: OrderState['fallback'];
  };
};

type ActionAddProductToBasket = {
  type: OrderActionType.ADD_PRODUCT_TO_BASKET;
  payload: {
    product: BasketProduct;
  };
};

type ActionSaveOrderFormData = {
  type: OrderActionType.SAVE_ORDER_FORM_DATA;
  payload: OrderState['orderFormData'];
};

type ActionClearBasket = {
  type: OrderActionType.CLEAR_BASKET;
};

type ActionClearOrder = {
  type: OrderActionType.CLEAR_ORDER;
  payload: {
    keepFormData: boolean;
  };
};

type ActionclearOrderFormData = {
  type: OrderActionType.CLEAR_ORDER_FORM_DATA;
};

type ActionSetFTTHPresalesInformation = {
  type: OrderActionType.SET_FTTH_PRESALES_INFORMATION;
  payload: OrderState['ftthPresalesInformation'];
};

type ActionSetInkassoTypeValidFrom = {
  type: OrderActionType.SET_INKASSO_TYPE_VALID_FROM;
  payload: OrderState['inkassoTypeValidFrom'];
};

type ActionSetAvailableProductOptions = {
  type: OrderActionType.SET_AVAILABLE_PRODUCT_OPTIONS;
  payload: OrderState['availableProductOptions'];
};

type ActionSetIsYoung = {
  type: OrderActionType.SET_IS_YOUNG;
  payload: OrderState['isYoung'];
};

type ActionSetBasketId = {
  type: OrderActionType.SET_BASKET_ID;
  payload: OrderState['basketId'];
};

type ActionSetShoppingCartData = {
  type: OrderActionType.SET_SHOPPING_CART_DATA;
  payload: OrderState['shoppingCartData'];
};

type ActionSetProducts = {
  type: OrderActionType.ADD_PRODUCT;
  payload: OrderState['products'];
};

type ActionSaveFormData = {
  type: OrderActionType.SAVE_FORM_DATA;
  payload: OrderState['formData'];
};

export type OrderAction =
  | ActionSetAddress
  | ActionSetAvailability
  | ActionAddProductToBasket
  | ActionSaveOrderFormData
  | ActionClearBasket
  | ActionClearOrder
  | ActionclearOrderFormData
  | ActionSetFTTHPresalesInformation
  | ActionSetInkassoTypeValidFrom
  | ActionSetAvailableProductOptions
  | ActionSetIsYoung
  | ActionSetBasketId
  | ActionSetShoppingCartData
  | ActionSetProducts
  | ActionSaveFormData;

export interface OrderContext {
  setAddress: (address: AddressSelection) => void;
  setAvailability: (
    maxAvailableDownload: OrderState['maxAvailableDownload'],
    plannedAvailability?: OrderState['plannedAvailability'],
    availableTv?: OrderState['availableTv'],
    promotions?: OrderState['promotions'],
    address?: OrderState['address'],
    fallback?: OrderState['fallback'],
  ) => void;
  setFTTHPresalesInformation: (
    objectInformation: ObjectInformation,
    alternativeProductAvailability: AvailabilityStatus,
  ) => void;
  fallback: OrderState['fallback'];
  ftthPresalesInformation: OrderState['ftthPresalesInformation'];
  addProductToBasket: (product: OrderState['basket']) => void;
  saveOrderFormData: (orderFormData: OrderState['orderFormData']) => void;
  clearBasket: () => void;
  clearOrder: (keepFormData?: boolean) => void;
  clearOrderFormData: () => void;
  address: OrderState['address'];
  maxAvailableDownload: OrderState['maxAvailableDownload'];
  setInkassoTypeValidFrom: (
    inkassoTypeValidFrom: OrderState['inkassoTypeValidFrom'],
  ) => void;
  inkassoTypeValidFrom: OrderState['inkassoTypeValidFrom'];
  plannedAvailability: OrderState['plannedAvailability'];
  availableTv: OrderState['availableTv'];
  basket: OrderState['basket'];
  basketId: OrderState['basketId'];
  shoppingCartData: OrderState['shoppingCartData'];
  setShoppingCartData: (
    shoppingCartData: OrderState['shoppingCartData'],
  ) => void;
  setBasketId: (basketId: OrderState['basketId']) => void;
  orderFormData: OrderState['orderFormData'];
  availableProductOptions: OrderState['availableProductOptions'];
  setAvailableProductOptions: (
    devices: Device[],
    ontDevice: OntDevice[],
    installationServices: InstallationService[],
  ) => void;
  isYoung: OrderState['isYoung'];
  setIsYoung: (isYoung: OrderState['isYoung']) => void;
  promotions: OrderState['promotions'];
  products: OrderState['products'];
  addProduct: (product: OrderState['products']) => void;
  formData: OrderState['formData'];
  saveFormData: (formData: OrderState['formData']) => void;
}

export enum LocalStorageKeys {
  Fallback = 'fallback',
  Address = 'address',
  MaxAvailableDownload = 'maxAvailableDownload',
  PlannedAvailability = 'plannedAvailability',
  AvailableTv = 'availableTv',
  Basket = 'basket',
  LastUpdate = 'lastUpdate',
  FTTHPresalesInformation = 'ftthPresalesInformation',
  InkassoTypeValidFrom = 'inkassoTypeValidFrom',
  AvailableProductOptions = 'availableProductOptions',
  IsYoung = 'isYoung',
  Technology = 'technology',
}

export enum SessionStorageKeys {
  OrderFormData = 'orderFormData',
  FormData = 'genericFormData',
}

export const storageExpiration = 90 * 24 * 60 * 60 * 1000; // 90 days

const necessaryStorageKeys = [
  LocalStorageKeys.Address,
  LocalStorageKeys.AvailableProductOptions,
  LocalStorageKeys.Basket,
  LocalStorageKeys.FTTHPresalesInformation,
  LocalStorageKeys.InkassoTypeValidFrom,
];

const hasStorage = storageAvailable();

const deleteStorage = (keepNecessary = false) => {
  if (hasStorage) {
    let storageKeysToDelete = Object.values(LocalStorageKeys);
    if (keepNecessary) {
      storageKeysToDelete = storageKeysToDelete.filter(
        (key) => !necessaryStorageKeys.includes(key),
      );
    }

    storageKeysToDelete.forEach((key) => {
      window.localStorage.removeItem(key);
    });

    Object.values(SessionStorageKeys).forEach((key) => {
      window.sessionStorage.removeItem(key);
    });
  }
};

const initializer = (initialValue: Partial<OrderState>): OrderState => {
  if (initialValue) {
    return {
      ...initialState,
      ...initialValue,
    };
  }
  if (hasStorage) {
    const lastUpdate = window.localStorage.getItem(LocalStorageKeys.LastUpdate);
    if (parseInt(lastUpdate) + storageExpiration < Date.now()) {
      deleteStorage();
    }
    return {
      fallback: initialState.fallback,
      address:
        getAddressParams() ||
        JSON.parse(window.localStorage.getItem(LocalStorageKeys.Address)) ||
        initialState.address,
      basket:
        JSON.parse(window.localStorage.getItem(LocalStorageKeys.Basket)) ||
        initialState.basket,
      basketId: initialState.basketId,
      shoppingCartData: initialState.shoppingCartData,
      maxAvailableDownload:
        JSON.parse(
          window.localStorage.getItem(LocalStorageKeys.MaxAvailableDownload),
        ) || initialState.maxAvailableDownload,
      plannedAvailability:
        JSON.parse(
          window.localStorage.getItem(LocalStorageKeys.PlannedAvailability),
        ) || initialState.plannedAvailability,
      availableTv:
        JSON.parse(window.localStorage.getItem(LocalStorageKeys.AvailableTv)) ||
        initialState.availableTv,
      orderFormData:
        JSON.parse(
          window.sessionStorage.getItem(SessionStorageKeys.OrderFormData),
        ) || initialState.orderFormData,
      ftthPresalesInformation:
        JSON.parse(
          window.localStorage.getItem(LocalStorageKeys.FTTHPresalesInformation),
        ) || initialState.ftthPresalesInformation,
      inkassoTypeValidFrom:
        window.localStorage.getItem(LocalStorageKeys.InkassoTypeValidFrom) ||
        initialState.inkassoTypeValidFrom,
      availableProductOptions:
        JSON.parse(
          window.localStorage.getItem(LocalStorageKeys.AvailableProductOptions),
        ) || initialState.availableProductOptions,
      isYoung: window.localStorage.getItem(LocalStorageKeys.IsYoung) === 'true',
      promotions: initialState.promotions,
      products: initialState.products,
      formData:
        JSON.parse(
          window.sessionStorage.getItem(SessionStorageKeys.FormData),
        ) || initialState.formData,
    };
  }
  return initialState;
};

const Context = createContext<OrderContext | null>(null);
Context.displayName = 'OrderContext';

const useOrderContext: () => OrderContext = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error(
      'useOrderContext must be used within a <OrderProvider> tag',
    );
  }
  return contextState;
};

type OrderProviderProps = {
  value?: Partial<OrderState>;
  children?: ReactNode;
};

const OrderProvider: FC<OrderProviderProps> = ({ value, children }) => {
  const [hasConsent, setHasConsent] = useState(true);
  const [state, dispatch] = useReducer(orderReducer, value, initializer);
  const consentUpdate = () => {
    setHasConsent(window['Cookiebot'].consent.preferences);
  };

  const setAddress = (address: OrderState['address']) => {
    dispatch({
      type: OrderActionType.SET_ADDRESS,
      payload: { address },
    });
  };

  const setFTTHPresalesInformation = (
    presalesInfo: ObjectInformation,
    alternativeProductAvailability: AvailabilityStatus,
  ) => {
    dispatch({
      type: OrderActionType.SET_FTTH_PRESALES_INFORMATION,
      payload: {
        minAvailableDownload: presalesInfo?.minBandwidth,
        maxAvailableDownload: Number(
          presalesInfo?.plannedBandwidth.replace(/\D/g, ''),
        ),
        isOffered: isFTTHOffered(
          presalesInfo?.status,
          alternativeProductAvailability,
        ),
        landingPage: presalesInfo?.landingpagePk,
        availabilityDate: presalesInfo?.availabilityDate,
        availabilityDateRaw: presalesInfo?.availabilityDateRaw,
        availabilityDateHalfYear: presalesInfo?.availabilityDateHalfYear,
        status: presalesInfo?.status,
        houseConnectionPrice: presalesInfo?.houseConnectionPrice,
        alternativeProductAvailability,
        hasGrantedGee: hasGrantedGee(
          presalesInfo?.geeStatus,
          presalesInfo?.geeValidFrom,
        ),
        expansionAccessType: presalesInfo?.ausbauAccessTyp,
        mergedAccessType: presalesInfo?.mergedAccessType,
        wholeBuy: presalesInfo?.wholebuy,
      },
    });
  };

  const setInkassoTypeValidFrom = (
    inkassoTypeValidFrom: OrderState['inkassoTypeValidFrom'],
  ) => {
    dispatch({
      type: OrderActionType.SET_INKASSO_TYPE_VALID_FROM,
      payload: inkassoTypeValidFrom,
    });
  };

  const setAvailableProductOptions = (
    devices: Device[],
    ontDevices: OntDevice[],
    installationServices: InstallationService[],
  ) => {
    dispatch({
      type: OrderActionType.SET_AVAILABLE_PRODUCT_OPTIONS,
      payload: {
        devices,
        ontDevices,
        installationServices,
      },
    });
  };

  const setAvailability = (
    maxAvailableDownload: OrderState['maxAvailableDownload'],
    plannedAvailability?: OrderState['plannedAvailability'],
    availableTv?: OrderState['availableTv'],
    promotions?: OrderState['promotions'],
    address?: OrderState['address'],
    fallback?: OrderState['fallback'],
  ) => {
    dispatch({
      type: OrderActionType.SET_AVAILABILITY,
      payload: {
        maxAvailableDownload,
        plannedAvailability,
        availableTv,
        promotions,
        address,
        fallback,
      },
    });
  };

  const addProductToBasket = (product: BasketProduct) => {
    dispatch({
      type: OrderActionType.ADD_PRODUCT_TO_BASKET,
      payload: { product },
    });
  };

  const saveOrderFormData = (orderFormData: OrderState['orderFormData']) => {
    dispatch({
      type: OrderActionType.SAVE_ORDER_FORM_DATA,
      payload: orderFormData,
    });
  };

  const clearBasket = () => {
    dispatch({ type: OrderActionType.CLEAR_BASKET });
  };

  const clearOrder = (keepFormData?: boolean) => {
    dispatch({
      type: OrderActionType.CLEAR_ORDER,
      payload: {
        keepFormData,
      },
    });
  };

  const clearOrderFormData = () => {
    dispatch({ type: OrderActionType.CLEAR_ORDER_FORM_DATA });
  };

  const setIsYoung = (isYoung: OrderState['isYoung']) => {
    dispatch({
      type: OrderActionType.SET_IS_YOUNG,
      payload: isYoung,
    });
  };

  const setBasketId = (basketId: OrderState['basketId']) => {
    dispatch({
      type: OrderActionType.SET_BASKET_ID,
      payload: basketId,
    });
  };

  const setShoppingCartData = (payload: OrderState['shoppingCartData']) => {
    dispatch({
      type: OrderActionType.SET_SHOPPING_CART_DATA,
      payload: payload,
    });
  };

  const addProduct = (payload: OrderState['products']) => {
    dispatch({
      type: OrderActionType.ADD_PRODUCT,
      payload: payload,
    });
  };

  const saveFormData = (payload: OrderState['formData']) => {
    dispatch({
      type: OrderActionType.SAVE_FORM_DATA,
      payload,
    });
  };

  useEffect(() => {
    setHasConsent(window['Cookiebot']?.consent?.preferences || true);
    window.addEventListener('CookiebotOnDecline', consentUpdate);
    window.addEventListener('CookiebotOnAccept', consentUpdate);

    return () => {
      window.removeEventListener('CookiebotOnDecline', consentUpdate);
      window.removeEventListener('CookiebotOnAccept', consentUpdate);
    };
  }, []);

  useEffect(() => {
    const {
      address,
      maxAvailableDownload,
      plannedAvailability,
      availableTv,
      basket,
      orderFormData,
      ftthPresalesInformation,
      inkassoTypeValidFrom,
      availableProductOptions,
      isYoung,
      formData,
    } = state;

    // always store necessary values
    if (hasStorage) {
      window.localStorage.setItem(
        LocalStorageKeys.Address,
        JSON.stringify(address),
      );

      window.localStorage.setItem(
        LocalStorageKeys.AvailableProductOptions,
        JSON.stringify(availableProductOptions),
      );

      window.localStorage.setItem(
        LocalStorageKeys.Basket,
        JSON.stringify(basket),
      );

      window.localStorage.setItem(
        LocalStorageKeys.FTTHPresalesInformation,
        JSON.stringify(ftthPresalesInformation),
      );

      window.localStorage.setItem(
        LocalStorageKeys.InkassoTypeValidFrom,
        inkassoTypeValidFrom,
      );
    }

    // store optional values if consent is given
    if (hasStorage && hasConsent) {
      window.localStorage.setItem(
        LocalStorageKeys.MaxAvailableDownload,
        JSON.stringify(maxAvailableDownload),
      );

      window.localStorage.setItem(
        LocalStorageKeys.PlannedAvailability,
        JSON.stringify(plannedAvailability),
      );

      window.localStorage.setItem(
        LocalStorageKeys.AvailableTv,
        JSON.stringify(availableTv),
      );

      window.localStorage.setItem(
        LocalStorageKeys.IsYoung,
        JSON.stringify(isYoung),
      );

      if (
        orderFormData &&
        'personal-password' in orderFormData &&
        'personal-password-confirm' in orderFormData
      ) {
        // prevent that the password will be saved into the session storage
        // this is only for the FTTH MVP. We should evaluate if https://github.com/dchest/tweetnacl-js is a suitable solution
        delete orderFormData['personal-password'];
        delete orderFormData['personal-password-confirm'];
      }

      window.sessionStorage.setItem(
        SessionStorageKeys.OrderFormData,
        JSON.stringify(orderFormData),
      );

      window.localStorage.setItem(
        LocalStorageKeys.LastUpdate,
        JSON.stringify(Date.now()),
      );

      window.sessionStorage.setItem(
        SessionStorageKeys.FormData,
        JSON.stringify(formData),
      );
    }
  }, [state, hasConsent]);

  useEffect(() => {
    if (!hasConsent) {
      deleteStorage(true);
    }
  }, [hasConsent]);

  useEffect(() => {
    if (state.address !== initialState.address) {
      const { zipCode, street, houseNumber, city } = state.address;
      checkAvailability({
        zipCode,
        street,
        houseNumber,
        ...(city && { city }),
      }).then(
        ({
          availablePromotions,
          presalePromotion,
          tvPromotions,
          hasFallback,
        }) => {
          const { maxAvailableDownload, plannedAvailability } = getMaxDownloads(
            {
              availablePromotions,
              presalePromotion,
            },
          );

          if (maxAvailableDownload) {
            setAvailability(
              maxAvailableDownload,
              plannedAvailability,
              tvPromotions,
              getTechnologyDownloads(availablePromotions, presalePromotion),
              null, // <- address
              hasFallback,
            );
          }
        },
      );
    }
  }, []);

  return (
    <Context.Provider
      value={{
        setAddress,
        setAvailability,
        setFTTHPresalesInformation,
        setInkassoTypeValidFrom,
        setAvailableProductOptions,
        addProductToBasket,
        setIsYoung,
        saveOrderFormData,
        clearBasket,
        clearOrder,
        clearOrderFormData,
        setBasketId,
        setShoppingCartData,
        addProduct,
        saveFormData,
        ...state,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { OrderProvider, useOrderContext };
