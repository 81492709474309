export * from './image';
export * from './getFormattedPriceString';
export const omit = <T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Omit<T, K> => {
  const _ = { ...obj };
  keys.forEach((key) => delete _[key]);
  return _;
};

export const pick = <T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Pick<T, K> => {
  const picked = {} as Pick<T, K>;
  keys.forEach((key) => {
    if (obj) {
      picked[key] = obj[key];
    }
  });
  return picked;
};
